import {
  makeObservable, observable, computed, action, reaction, autorun, toJS
} from 'mobx';
import {
  ProductDetail, ProductsDetails
} from '../services/PaymentsService';
import {calculateAmount} from '../utils';

class UserStore {
  cartContents = []

  constructor() {
    makeObservable(this, {
      cartContents: observable,
      removeItem: action,
      changeQuantity: action,
      addItem: action,
      clearCart: action
    });
    const cartItems = localStorage.getItem('sponsor_cart');
    if(cartItems){
      this.cartContents = JSON.parse(cartItems);
    }
    autorun(()=>{
      localStorage.setItem('sponsor_cart', JSON.stringify(this.cartContents));
    });
  }

  removeItem(id){
    this.cartContents = this.cartContents.filter((item)=> item.pricingId !== id);
  }

  async changeQuantity(pricingId, quantity){
    const index = this.cartContents.findIndex((item)=>item.pricingId === pricingId );
    this.cartContents[index].quantity = quantity;
  }
  async addItem(pricingId, quantity){
    const index = this.cartContents.findIndex((item)=>item.pricingId === pricingId);
    if (index ===-1){
      this.cartContents.push({
        quantity,
        pricingId,
      });
    } else{
      this.cartContents[index].quantity += quantity;
    }
  }

  clearCart(){
    this.cartContents = [];
  }
}

export default new UserStore();
