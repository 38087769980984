import React, {Suspense} from 'react';
import {
  Route, Switch, Redirect
} from 'react-router-dom';
import { Routes } from '../routes';

// pages
import Signin from './examples/Signin';
import Signup from './examples/Signup';
import ForgotPassword from './examples/ForgotPassword';
import NotFoundPage from './examples/NotFound';

// documentation pages

// components
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';

import EmailVerification from './examples/EmailVerification';
import getStore from '../stores';
import ResetPassword from './examples/ResetPassword';
import SubSponsors from './SubSponsors';
import {observer} from 'mobx-react';
import ProductPage from './payment';
import Cart from './payment/cart';

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Groups from './Groups';
import GroupMembers from './Groups/GroupMembers';

const RouteWithLoader = ({ component: Component, ...rest }) => {


  return (
    <Route {...rest} render={(props)=><Component {...props}/>} />
  );
},

      RouteWithSidebar = ({ component: Component, authRequired=true, ...rest  }) => {
        const authFilter =  (child)=>{
          if(authRequired && !getStore('UserStore').isTokenValid){
            getStore('UserStore').logout();
            return ()=>(<Redirect to={Routes.Signin.path}/>);
          } else {
            return child;
          }
        };
        return (
          <Route {...rest} render={authFilter(props => (
            <>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
              />
              <Sidebar />

              <main className="content">
                <Navbar />
                <Component {...props} />
                {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
              </main>
            </>
          ))}
          />
        );
      };

export default observer(() => (
  <Suspense fallback={()=><p>Loading</p>}>

    <Switch>
      {/*<RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />*/}
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.EmailVerification.path} component={EmailVerification} />
      {/*<RouteWithLoader exact path={Routes.MFAVerification.path} component={MFAVerification} />*/}
      {/*<RouteWithLoader exact path={Routes.MFAValidation.path} component={MFAValidation} />*/}
      {/*<RouteWithLoader exact path={Routes.MFASetup.path} component={MFASetup} />*/}
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader path={Routes.ResetPassword.path} component={ResetPassword} />
      {/*<RouteWithLoader exact path={Routes.Lock.path} component={Lock} />*/}
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      {/*<RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />*/}


      {/* pages */}

      {Object.keys(Routes).map(function (key){
        let value = Routes[key];
        if(!value.component){
          return null;
        }
        if (value.sidebar){
          return <RouteWithSidebar exact={value.exact} path={value.path} component={value.component} authRequired={value.authRequired}/>;
        } else
          return <RouteWithLoader exact={value.exact} path={value.path} component={value.component} authRequired={value.authRequired}/>;
      })}

      {/*<RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />*/}
      {/*<RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />*/}
      {getStore('UserStore').userType === 'ADMINISTRATIVE' && <RouteWithSidebar exact path={Routes.SubSponsors.path} component={SubSponsors}/>}
      {/*<RouteWithSidebar exact path={Routes.BuyTests.path} component={StripeTest} />*/}
      <RouteWithSidebar path="/products/:productId" component={ProductPage} />
      <RouteWithSidebar exact path="/cart" component={Cart} />
      {getStore('UserStore').data.is_corporate && <RouteWithSidebar exact path={Routes.Groups.path} component={Groups}/>}
      {getStore('UserStore').data.is_corporate && <RouteWithSidebar exact path={Routes.GroupMembers.path} component={GroupMembers}/>}


      <Route exact path="/">
        <Redirect to={Routes.Signin.path}/>
      </Route>
      {/* components */}
      {/*<RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />*/}
      {/*<RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />*/}
      {/*<RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />*/}
      {/*<RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />*/}
      {/*<RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />*/}
      {/*<RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />*/}
      {/*<RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />*/}
      {/*<RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />*/}
      {/*<RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />*/}
      {/*<RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />*/}
      {/*<RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />*/}
      {/*<RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />*/}
      {/*<RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />*/}
      {/*<RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />*/}
      {/*<RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />*/}
      {/*<RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />*/}
      {/**/}
      {/* documentation */}
      {/*<RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />*/}
      {/*<RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />*/}
      {/*<RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />*/}
      {/*<RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />*/}
      {/*<RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />*/}
      {/*<RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />*/}
      {/*<RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />*/}

      <Route path="*"> <NotFoundPage/></Route>
    </Switch>
  </Suspense>
));
