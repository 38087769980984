import ReconnectingWebSocket from 'reconnecting-websocket';

let sockets = {};

export function getSocket(url){
  if (sockets[url]){
    return sockets[url];
  } else {
    let socket = new ReconnectingWebSocket(url);
    sockets[url] = socket;
    return socket;
  }
}
