import HTTPService from './HTTPService';

export async function SignUpService({email, name, password}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/signup`,
    method: 'POST',
    json: {
      email,
      name,
      password
    }
  });
}

export async function ResetPasswordService({oldPassword, newPassword}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/change-password`,
    method: 'POST',
    json:
  {
    currentPassword: oldPassword,
    password: newPassword
  }
  });
}

export async function UpdateProfileService({age, income, name}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/update-profile`,
    method: 'PUT',
    json:
  {
    age,
    income,
    name
  }
  });
}

export async function GetProfileService(){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/get-profile`,
    method: 'GET'
  });
}

export async function EmailVerificationService({verificationCode}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/verifyEmail`,
    method: 'POST',
    json:
  {verificationCode}
  });
}

export async function ResendEmailOtpService(){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/resendVerificationEmail`,
    method: 'GET'
  });
}

// export async function GenerateTotpUrlService(){
//   return HTTPService('http://localhost:3001/dev/affiliate/generateGoogleAuthLink','GET')
// }
//
//
// export async function TOTPValidationService({verificationCode}){
//   return HTTPService('http://localhost:3001/dev/affiliate/verifyOtpSetup','POST',{verificationCode})
// }

export async function SignInService({email, password}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/sponsor/login`,
    method: 'POST',
    json: {
      email,
      password
    },
    options: {credentials: 'include'}
  });
}

// export async function TOTPVerificationService({verificationCode}){
//   return HTTPService('http://localhost:3001/dev/affiliate/verifyOtp','POST',{verificationCode})
// }

export async function ForgotPasswordService({email}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/sponsor/forget-password`,
    method: 'POST',
    json: {email}
  });
}

export async function ResetPasswordTokenVerificationService({token, userId}){
  const searchParams = new URLSearchParams({
    token,
    userId
  });
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/sponsor/forget-password/token-verification?${searchParams.toString()}`,
    method: 'GET'
  });
}

export async function ResetPasswordChangeService({token, password, userId}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/sponsor/forget-password/change`,
    method: 'POST',
    json: {
      token,
      password,
      userId
    }
  });
}
