
import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faSignOutAlt,
  faTable,
  faTimes,
  faUserCircle,
  faCog,
  faCartArrowDown, faDollarSign, faLink, faUsers, faInfoCircle, faBook, faStore
} from '@fortawesome/free-solid-svg-icons';
import {
  Nav, Badge, Image, Button, Accordion, Navbar
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from '../routes';
// import ThemesbergLogo from '../assets/img/themesberg.svg';
import ReactHero from '../assets/img/technologies/react-hero-logo.svg';
import ProfilePicture from '../assets/img/team/profile-picture-3.jpg';
import getStore from '../stores';
import Avatar from 'react-avatar';
import {faComments} from '@fortawesome/free-regular-svg-icons';

export default (props = {}) => {
  const location = useLocation(),
        { pathname } = location,
        [show, setShow] = useState(false),
        showClass = show ? 'show' : '',

        onCollapse = () => setShow(!show),

        CollapsableNavItem = (props) => {
          const { eventKey, title, icon, children = null } = props,
                defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

          return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
              <Accordion.Item eventKey={eventKey}>
                <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
                    <span className="sidebar-text">{title}</span>
                  </span>
                </Accordion.Button>
                <Accordion.Body className="multi-level">
                  <Nav className="flex-column">
                    {children}
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        },

        NavItem = (props) => {
          const { title, link, external, target, icon, image, badgeText, badgeBg = 'secondary', badgeColor = 'primary' } = props,
                classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '',
                navItemClassName = link === pathname ? 'active' : '',
                linkProps = external ? { href: link } : {
                  as: Link,
                  to: link
                };

          return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
              <Nav.Link {...linkProps} target={target} className={classNames}>
                <span>
                  {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                  {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

                  <span className="sidebar-text">{title}</span>
                </span>
                {badgeText ? (
                  <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                ) : null}
              </Nav.Link>
            </Nav.Item>
          );
        };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Avatar name={getStore('UserStore').data.name} src={getStore('UserStore').data.profile} round={true} size={32}/>
                  {/*<Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />*/}
                </div>
                <div className="d-block">
                  <h6>{getStore('UserStore').data.name}</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {/*<NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} />*/}

              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
              <CollapsableNavItem eventKey="generated" title="Generated Tests" icon={faLink}>
                <NavItem title="Profile Tests" link={Routes.ProfileGeneratedTests.path}  />
                <NavItem title="FWS Tests" link={Routes.FWSGeneratedTests.path} />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="reports" title="Reports" icon={faTable}>
                <NavItem title="Profile Reports" link={Routes.PsychometricsReports.path} />
                <NavItem title="FWS Reports" link={Routes.FWSReports.path} />
              </CollapsableNavItem>
              {/*<NavItem title="Chat" link={Routes.Chat.path} icon={faComments} />*/}
              <NavItem title="Resources" icon={faBook} link={Routes.ResourceList.path} />
              {getStore('UserStore').data.is_corporate && <NavItem title="Groups" icon={faUsers} link={Routes.Groups.path}/>}
              {getStore('UserStore').userType === 'ADMINISTRATIVE' && <NavItem title="Sub Sponsors" icon={faUsers} link={Routes.SubSponsors.path}/>}
              <NavItem title="Store" icon={faStore} link={Routes.Products.path} />
              <NavItem title="My Orders" icon={faCartArrowDown} link={Routes.Orders.path} />
              {/*<NavItem title="Buy Tests" icon={faDollarSign} link={Routes.BuyTests.path} />*/}
              <CollapsableNavItem eventKey="/user-profile" title="My Profile" icon={faUserCircle}>
                <NavItem title="My Details" link={Routes.Profile.path} />
                <NavItem title="Report Preview" link={Routes.ReportPreview.path} />
              </CollapsableNavItem>
              <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />


              {/*<Dropdown.Divider className="my-3 border-indigo" />*/}

              {/*<CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>*/}
              {/*  <NavItem title="Overview" link={Routes.DocsOverview.path} />*/}
              {/*  <NavItem title="Download" link={Routes.DocsDownload.path} />*/}
              {/*  <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />*/}
              {/*  <NavItem title="License" link={Routes.DocsLicense.path} />*/}
              {/*  <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />*/}
              {/*  <NavItem title="Build Tools" link={Routes.DocsBuild.path} />*/}
              {/*  <NavItem title="Changelog" link={Routes.DocsChangelog.path} />*/}
              {/*</CollapsableNavItem>*/}
              {/*<CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>*/}
              {/*  <NavItem title="Accordion" link={Routes.Accordions.path} />*/}
              {/*  <NavItem title="Alerts" link={Routes.Alerts.path} />*/}
              {/*  <NavItem title="Badges" link={Routes.Badges.path} />*/}
              {/*  <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />*/}
              {/*  <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />*/}
              {/*  <NavItem title="Buttons" link={Routes.Buttons.path} />*/}
              {/*  <NavItem title="Forms" link={Routes.Forms.path} />*/}
              {/*  <NavItem title="Modals" link={Routes.Modals.path} />*/}
              {/*  <NavItem title="Navbars" link={Routes.Navbars.path} />*/}
              {/*  <NavItem title="Navs" link={Routes.Navs.path} />*/}
              {/*  <NavItem title="Pagination" link={Routes.Pagination.path} />*/}
              {/*  <NavItem title="Popovers" link={Routes.Popovers.path} />*/}
              {/*  <NavItem title="Progress" link={Routes.Progress.path} />*/}
              {/*  <NavItem title="Tables" link={Routes.Tables.path} />*/}
              {/*  <NavItem title="Tabs" link={Routes.Tabs.path} />*/}
              {/*  <NavItem title="Toasts" link={Routes.Toasts.path} />*/}
              {/*  <NavItem title="Tooltips" link={Routes.Tooltips.path} />*/}
              {/*</CollapsableNavItem>*/}
              {/*<NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />*/}

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
