import HTTPService from './HTTPService';

export async function CreateChatService({test_taker_id}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/start/${test_taker_id}`,
    method: 'POST'
  });
}

export async function ConversationsList(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/conversations`,
    method: 'GET'
  });
}
export async function ConversationsHistory({conversationId}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/conversations/${conversationId}/history`,
    method: 'GET'
  });
}
export async function ConversationDetails(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/conversations`,
    method: 'GET'
  });
}
