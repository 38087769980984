import HTTPService from './HTTPService';

export async function FWSTests({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/fws?${searchParams.toString()}`});
}

export async function FWSTestsCount(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/fws/count`});
}

export async function PsychometricsTests({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/psychometrics?${searchParams.toString()}`});
}

export async function PsychometricsTestsCount(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/psychometrics/count`});
}

export async function LatestTests({from=0, size=10}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/reports/latestTests?${searchParams.toString()}`});
}

export async function DeleteFwsTestLinks({testId}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/fws/delete`,
    method: 'post',
    json: {link_uuid: testId}
  });
}

export async function FWSTestLinks({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/fws/generated-tests?${searchParams.toString()}`});
}

export async function FWSTestLinksCount(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/fws/generated-tests/count`});
}

export async function FWSTestLinkAddCredit({link_uuid, credits}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/fws/add-credit`,
    method: 'POST',
    json: {
      link_uuid,
      credits
    }
  });
}

export async function ProfileTestLinks({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/generated-tests?${searchParams.toString()}`});
}

export async function ProfileTestLinksCount(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/generated-tests/count`});
}

export async function ProfileTestLinkAddCredit({link_uuid, credits}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/add-credit`,
    method: 'POST',
    json: {
      link_uuid,
      credits
    }
  });
}

export async function DeleteProfileTestLinks({testId}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/delete`,
    method: 'post',
    json: {link_uuid: testId}
  });
}

export async function GenerateLink({campaign, credits, test_type}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/tests${test_type === 'fws' ? '/fws' : ''}/generate-link`,
    method: 'post',
    json: {
      campaign,
      credits
    }
  });
}

export async function RecentTests({count}){
  const searchParams = new URLSearchParams({count});
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/recent?${searchParams.toString()}`});
}


export async function GetReportViewUrl({report_type, report_id}){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/${report_type}/${report_id}/token`});
}
