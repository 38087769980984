import {lazy} from 'react';

export const Routes = {
  // pages
  // Presentation: { path: "/" },
  DashboardOverview: {
    path: '/dashboard/overview',
    component: lazy(()=>import('../src/pages/dashboard/DashboardOverview')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  Transactions: { path: '/transactions' },
  Settings: {
    path: '/settings',
    component: lazy(()=>import('./pages/Settings')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  Profile: {
    path: '/user-profile',
    component: lazy(()=>import('./pages/SponsorProfile')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  Upgrade: { path: '/upgrade' },
  Orders: {
    path: '/orders',
    component: lazy(()=>import('./pages/Orders')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  // BuyTests: {path: '/buy-tests'},
  PsychometricsReports: {
    path: '/reports/psychometrics',
    component: lazy(()=>import('./pages/ProfileReports')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  FWSReports: {
    path: '/reports/fws',
    component: lazy(()=>import('./pages/FWSReports')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  FWSGeneratedTests: {
    path: '/generated/fws',
    component: lazy(()=>import('../src/pages/FWSLinks/index')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  ProfileGeneratedTests: {
    path: '/generated/profile-links',
    component: lazy(()=>import('./pages/ProfileLinks')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  SubSponsors: {path: '/sub-sponsors'},
  // Chat: {
  //   path: '/chat',
  //   component: lazy(()=>import('./chat')),
  //   sidebar: true,
  //   exact: true,
  //   authRequired: true
  // },
  // BootstrapTables: { path: '/tables/bootstrap-tables' },
  // Billing: { path: '/examples/billing' },
  // Invoice: { path: '/examples/invoice' },
  Signin: { path: '/sign-in' },
  Signup: { path: '/sign-up' },
  EmailVerification: { path: '/email-verification' },
  // MFAValidation: { path: '/mfa-validation' },
  // MFAVerification: { path: '/mfa-verification' },
  // MFASetup: { path: '/mfa-setup' },
  ForgotPassword: { path: '/forgot-password' },
  ResetPassword: { path: '/password-reset/:userId/:resetToken' },
  Lock: { path: '/examples/lock' },
  NotFound: { path: '/examples/404' },
  ServerError: { path: '/examples/500' },
  AccountSettings: {path: '/user-profile/account-settings'},
  ReportPreview: {
    path: '/user-profile/report-preview',
    component: lazy(()=>import('./pages/PDF/ProfilePDFPreview')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  ResourceList: {
    path: '/resource',
    component: lazy(()=>import('./pages/Resource')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  ResourceVideos: {
    path: '/resource/:resourceId',
    component: lazy(()=>import('./pages/Resource/ResourceVideo')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  Products: {
    path: '/products',
    component: lazy(()=>import('./pages/payment')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  TestTakerInfo: {
    path: '/test-taker-info/:userId',
    component: lazy(()=>import('./pages/TestTakersInfo/TestTakerInfo')),
    sidebar: true,
    exact: true,
    authRequired: true
  },
  Groups: { path: '/groups'},
  GroupMembers: { path: '/groups/:groupId'}


  // docs
  // DocsOverview: { path: '/documentation/overview' },
  // DocsDownload: { path: '/documentation/download' },
  // DocsQuickStart: { path: '/documentation/quick-start' },
  // DocsLicense: { path: '/documentation/license' },
  // DocsFolderStructure: { path: '/documentation/folder-structure' },
  // DocsBuild: { path: '/documentation/build-tools' },
  // DocsChangelog: { path: '/documentation/changelog' },

  // components
  // Accordions: { path: '/components/accordions' },
  // Alerts: { path: '/components/alerts' },
  // Badges: { path: '/components/badges' },
  // Widgets: { path: '/widgets' },
  // Breadcrumbs: { path: '/components/breadcrumbs' },
  // Buttons: { path: '/components/buttons' },
  // Forms: { path: '/components/forms' },
  // Modals: { path: '/components/modals' },
  // Navs: { path: '/components/navs' },
  // Navbars: { path: '/components/navbars' },
  // Pagination: { path: '/components/pagination' },
  // Popovers: { path: '/components/popovers' },
  // Progress: { path: '/components/progress' },
  // Tables: { path: '/components/tables' },
  // Tabs: { path: '/components/tabs' },
  // Tooltips: { path: '/components/tooltips' },
  // Toasts: { path: '/components/toasts' },
  // WidgetsComponent: { path: '/components/widgets' }
};
