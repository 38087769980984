import HTTPService from './HTTPService';

export async function AllResourcesList(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/resource`
  });
}

export async function ResourceDetails({resourceId}){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/resource/${resourceId}`
  });
}
