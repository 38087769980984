import React, {
  useEffect, useState
} from 'react';
import {
  Button, Card, Image, Modal, Form, Row, Col, Table, Alert
} from '@themesberg/react-bootstrap';
import {
  Link, useHistory, useParams
} from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle, faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import getStore from '../../stores';
import _ from 'lodash';
import {
  ProductDetail, ProductList
} from '../../services/PaymentsService';
import classnames from 'classnames';
import {calculateAmount} from '../../utils';
import {toast} from 'react-toastify';
import {RingLoader} from 'react-spinners';
import {css} from '@emotion/react';

const loaderCSS = css`
  display: inline-block;
`;

function ProductCard({id, name, description, image, pricing}){
  const billing_scheme = _.get(pricing, '0.billing_scheme');
  return <Card style={{ width: '18rem' }} className="h-100">
    <Card.Img variant="top" style={{height: '18rem'}} src={image} />
    <Card.Body className="d-flex flex-column">
      <Card.Title>{name}</Card.Title>
      <Card.Text>{description}</Card.Text>
      <Card.Text><span className="d-block">{billing_scheme === 'Tiered' ? 'Starting from':'\u00A0'}</span>
        <span className='h5'>US{getSymbolFromCurrency(_.get(pricing, '0.currency'))}{billing_scheme === 'Tiered'?_.get(pricing, '0.tiers.0.unit_amount'): _.get(pricing, '0.unit_amount')}</span> per unit</Card.Text>
      <Button className="mb-0 mt-auto" variant="primary" as={Link} to={`/products/${id}`} >Buy</Button>
    </Card.Body>
  </Card>;
}

function ProductModal({productId}){
  const history = useHistory(),
        [loading, setLoading] = useState(false),
        [product, setProduct] = useState({});

  // TODO: Add error handling
  useEffect(()=>{
    if (!productId){
      return;
    }
    setLoading(true);
    ProductDetail(productId)
      .then((resp)=>{
        setProduct(resp.product);
      }).finally(()=>{
        setLoading(false);
      });
  }, [productId]);

  let [quantity, setQuantity] = useState(1);
  const addToCart = (pricingId, count)=>{
    const store = getStore('CartStore');
    store.addItem(pricingId, count);
    history.push('/products');
    toast.success('Added to cart');
  },
        base_price = _.get(product, 'pricing.0', {});

  return <Modal as={Modal.Dialog} scrollable centered show={!!productId} onHide={()=>history.push('/products')} size={base_price.billing_scheme === 'volume' ?'xl':'lg'}>
    <Card className="h-100 flex-column overflow-hidden">
      <Card.Header className="flex-grow-0 flex-shrink-0">
        <Card.Title className="display-4 mb-0">{product.name}</Card.Title>
      </Card.Header>
      {loading ? <div className="w-100 text-center">
        <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
      </div> :
        <Card.Body className="overflow-auto">
          <Row>
            <Col xs={base_price.billing_scheme === 'Tiered'?6:12}>
              <div className={classnames({'sticky-top': base_price.billing_scheme === 'Tiered'})}>
                <Image style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain'
                }} thumbnail className="me-3 float-start"
                src={product.image}/>
                <Card.Title
                  className="display-5">US{getSymbolFromCurrency(base_price.currency)}{(calculateAmount(quantity, base_price)).toFixed(2)}</Card.Title>
                <div className="d-flex align-items-center mb-2">
                  <FontAwesomeIcon icon={faMinusCircle} size="lg" onClick={() => {
                    setQuantity(Math.max(0, quantity - 1));
                  }}/>
                  <Form.Control className="mx-2 text-center" as="input" value={quantity} size="sm" htmlSize={3}
                    style={{width: 'unset'}} onChange={(e) => {
                      !Number.isNaN(parseInt(e.target.value)) && setQuantity(parseInt(e.target.value));
                    }}/>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg" onClick={() => {
                    setQuantity(quantity + 1);
                  }}/>
                </div>
                <p>{product.description}</p>
              </div>
            </Col>
            {base_price.billing_scheme === 'Tiered' && <Col xs={6} className="overflow-auto">
              <Table bordered striped className="w-100">
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {base_price.tiers && base_price.tiers.map((item, idx) =>
                    <tr key={idx}>
                      <td>{_.get(base_price.tiers, [idx - 1, 'upto'], 0) + 1}-{_.get(base_price.tiers, [idx, 'upto'], '∞')}</td>
                      <td>{item.unit_amount}</td>
                    </tr>)}
                </tbody>
              </Table>
            </Col>}
          </Row>
        </Card.Body>}
      <Card.Footer className="d-flex flex-grow-0 flex-shrink-0 justify-content-center ">
        <Button className="mb-0 w-50 mt-auto" variant="primary" disabled={quantity === 0 || loading} onClick={()=>addToCart(base_price.id, quantity)}>Add to Cart</Button>
      </Card.Footer>
    </Card>
  </Modal>;
}

export default function ProductPage(){
  const {productId} = useParams(),
        [loading, setLoading] = useState(false),
        [productList, setProductList] = useState([]);

  // TODO: Add error handling
  useEffect(()=>{
    setLoading(true);
    ProductList()
      .then((resp)=>{
        setProductList(resp.products);
      })
      .catch(()=>{

      }).finally(()=>{
        setLoading(false);
      });
  }, []);

  return (<div className="d-flex">
    {loading ? <div className="w-100 text-center">
      <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
    </div> :
      (productList.length === 0 ?  <Alert className='w-100 text-center' variant="warning">
            No products available right now.
      </Alert>:
        productList.map((item)=>
          <div className='me-3 mb-3' key={item.id}>
            <ProductCard {...item} key={item.id}/>
          </div>))
    }
    {productId && <ProductModal productId={productId}/>}
  </div>
  );
}
