import React, {
  useCallback,
  useEffect, useRef, useState
} from 'react';
import {
  Alert,
  Button, Card, Col, Form, FormControl, Image, InputGroup, Modal, Row
} from '@themesberg/react-bootstrap';
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';
import {
  faGlobeEurope, faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  SponsorProfile, UpdateSponsorProfile
} from '../../services/SponsorProfileService';
import * as Yup from 'yup';
import {
  FORM_ERROR, setIn
} from 'final-form';
import {captureException} from '@sentry/react';
import {
  getCroppedImg, isProfileComplete
} from '../../utils';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import {RingLoader} from 'react-spinners';
import Chips from 'react-chips';
import chipTheme from './chipTheme';
import _ from 'lodash';
import {
  CreateGroupService, UpdateGroupDetails
} from '../../services/GroupServices';
import {DeleteProfileTestLinks} from '../../services/ReportService';
import {DeleteProfileLinkModal} from '../ProfileLinks/modals';
import {toast} from 'react-toastify';
import {DeleteGroupModal} from './modals';
import {Routes} from '../../routes';
import {useHistory} from 'react-router-dom';


export function AddNewGroupCard({onClick}){
  return (
    <Card style={{
      width: '18rem',
      cursor: 'pointer',
      minHeight: '20rem',
    }} className="border-0 text-center bg-gray-500 d-flex align-content-center justify-content-center flex-column mb-3" onClick={onClick}>

      <div>
        <FontAwesomeIcon icon={faPlusCircle} className="icon icon-xs" style={{
          height: '100px',
          width: '100px'
        }} />
      </div>
      <h5 className="mt-2">Add New Group</h5>
    </Card>
  );
}


export const GroupInfoForm  = ({groupInfo, groupId}) => {
  let [loading, setLoading] = useState(false),
      [deleteGroupId, setDeleteGroupId] = useState({}),
      [error, setError] = useState(''),
      history = useHistory(),
      [success, setSuccess] = useState(false);


  const schema = Yup.object(
    {
      name: Yup.string().required('Group name is Required'),
      description: Yup.string(),
      image: Yup.array().ensure().min(1).max(1)
    }),
        validate = async (values) => {
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
          }
        },

        submit = async (body)=>{
          setLoading(true);
          setError();
          setSuccess();
          try{
            body.groupId = groupId;
            const resp = await UpdateGroupDetails(body);
            if(!resp.success){
              setError(resp.message || 'Error occurred while saving group details');
              return resp.errors || {[FORM_ERROR]: 'Error occurred while saving group details'};
            } else {
              groupInfo = resp.group;
              setSuccess(true);
              // setTimeout(()=>setSuccess(false), 2000);
              // toast.success('Group details updated successfully');
              window.location.reload();
            }

          } catch (err){
            console.error(err);
            captureException(err);
            return {[FORM_ERROR]: 'Error occurred while saving group details'};
          } finally {
            setLoading(false);
          }
        };


  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <FormHandler onSubmit={submit}
          validate={validate}
          initialValues={groupInfo}
          render={({handleSubmit, submitting, hasValidationErrors, pristine})=>(
            <Form onSubmit={handleSubmit} id="update-group-details-form">
              <h5 className="mb-4">Group Details</h5>

              <Row>
                <Col xs={4}>
                  <div className="position-relative rounded-circle overflow-hidden">
                    <Field name="image" render={({input})=><GroupImageField files={input.value} onChange={({target})=>input.onChange(target.files)} currentGroupImage={groupInfo.image}/>}/>
                  </div>
                </Col>
                <Col xs={8}>
                  <Form.Group id="name">
                    <Form.Label>Group Name</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='name' render={({input, meta})=>(<>
                        <Form.Control required isInvalid={meta.touched && meta.error} type="text" placeholder="Group name" {...input}/>
                        {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                      </>)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="description">
                    <Form.Label>Description</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='description' render={({input, meta})=>(<>
                        <Form.Control required as="textarea" rows="13" isInvalid={meta.touched && meta.error} type="textarea" placeholder="Group Description" {...input}/>
                        {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                      </>)}/>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <div className="mt-3 d-flex">
                <Button variant="primary" disabled={loading || pristine} type="submit" className="d-inline-flex flex-grow-1 justify-content-center align-items-center">
                    Save{loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }
                </Button>
                <Button variant="danger" className="ms-2 d-inline-flex flex-grow-1 justify-content-center align-items-center" onClick={() => setDeleteGroupId({
                  groupId,
                  groupName: groupInfo.name
                })}>
                  Delete
                </Button>
              </div>

            </Form>)}/>
      </Card.Body>
      {!_.isEmpty(deleteGroupId) && <DeleteGroupModal groupInfo={deleteGroupId} requestClose={() => {
        setDeleteGroupId({});
        history.push(Routes.Groups.path);
      }}/>}
    </Card>
  );
};

export function GroupCard({ name, image, id, description}) {
  return (
    <Card style={{ width: '18rem' }} className="h-100">
      <Card.Img className="rounded-circle" variant="top" style={{
        height: '18rem',
        objectFit: 'contain'
      }} src={image} />
      <Card.Body className="d-flex flex-column">
        <Card.Title title={name} style={{
          maxWidth: '100%',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }} className="">{name}</Card.Title>
        <Card.Subtitle style={{
          maxWidth: '100%',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box'
        }}title={description}>{description}</Card.Subtitle>
      </Card.Body>
    </Card>
  );
}

//TODO duplicate groupImageField - refractor it
function GroupImageField({files, onChange, image, requestClose, aspectRatio, submit, currentGroupImage}){
  const [crop, setCrop] = useState({
    x: 0,
    y: 0
  }),
        [zoom, setZoom] = useState(1),
        [croppedAreaPixels, setCroppedAreaPixels] = useState(null),
        [croppedImage, setCroppedImage] = useState(null),

        onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
          setCroppedAreaPixels(croppedAreaPixels);
        }, []);

  useEffect(()=> {
    (async () => {
      try {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels
        );
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [croppedAreaPixels, image]);


  const onSubmit = ()=>{

    submit(croppedImage)
      .then(requestClose)
      .catch((err)=>{
        captureException(err);
      });
  },
        [src, setSrc] = useState(),
        [isMouseInside, setMouseInside] = useState(false),
        containerRef = useRef(),
        [containerHeight, setContainerHeight] = useState(0);

  useEffect(()=>{

    let observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.contentBoxSize){
          setContainerHeight(entry.contentRect.width);
        }
      }
    });
    observer.observe(containerRef.current);
    setContainerHeight(containerRef.current.offsetWidth);
    return ()=>observer.disconnect();
  }, [src]);


  return <div ref={containerRef} className="position-relative rounded-circle overflow-hidden" onMouseEnter={()=>setMouseInside(true)} onMouseLeave={()=>setMouseInside(false)}>
    <Image style={{
      height: `${containerHeight}px`,
      objectFit: 'contain'
    }} fluid roundedCircle src={src||currentGroupImage} />
    <input className="position-absolute" style={{
      left: 0,
      bottom: 0,
      height: '45px',
      width: '100%',
      opacity: 0,
      zIndex: 10,
      cursor: 'pointer'
    }} type="file" accept="image/*" onChange={(e)=> {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setSrc(reader.result )
      );
      reader.readAsDataURL(e.target.files[0]);
      onChange(e);
    }}/>
    {isMouseInside && <div className="d-flex bg-light position-absolute justify-content-center align-items-center w-100" style={{
      left: 0,
      bottom: 0,
      height: '45px',
      zIndex: 1,
      cursor: 'pointer'
    }}>
      Change
    </div>}
  </div>;
}

