import React, {useState, useEffect} from 'react';
import {SubSponsorsTable} from './table';
import {SubSponsorsList} from '../../services/SponsorManagementService';

export default function SubSponsors () {
  let [subSponsors, setSubSponsors] = useState([]),
      [loading, setLoading] = useState(false);

  const handleClose = ()=>{
    setLoading(true);
    SubSponsorsList()
      .then((data)=>{
        setSubSponsors(data.sponsors);
      })
      .finally(()=>{
        setLoading(false);
      });
  };
  useEffect(()=>{
    handleClose();
  }, []);



  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <h4>Sub Sponsors</h4>
        </div>
      </div>
      <SubSponsorsTable subSponsors={subSponsors} onChange={handleClose} loading={loading}/>
    </>
  );
}
