
import React, {
  useState, useEffect
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faChartArea,
  faChartBar,
  faChartLine,
  faFlagUsa,
  faFolderOpen,
  faGlobeEurope,
  faPaperclip,
  faExternalLinkAlt,
  faCashRegister,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  ListGroup,
  ProgressBar,
  Form,
  FormControl
} from '@themesberg/react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import {
  FWSTests, GenerateLink
} from '../services/ReportService';
import {
  CircleChart, BarChart, SalesValueChart, SalesValueChartphone
} from './Charts';


import {SponsorCredits} from '../services/SponsorProfileService';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import * as Yup from 'yup';
import {
  FORM_ERROR, setIn
} from 'final-form';
import {
  CampaignDetailsList, SubSponsorsList
} from '../services/SponsorManagementService';
import {RingLoader} from 'react-spinners';
import {css} from '@emotion/react';
import copy from 'copy-to-clipboard';
import {TransferCreditsModal} from '../pages/SubSponsors/modals';
import {Link} from 'react-router-dom';
import {Routes} from '../routes';
import {toast} from 'react-toastify';
import {CreateGroupLink} from '../services/GroupServices';

export const ChoosePhotoWidget = (props) => {
  const { title, photo } = props;

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">{title}</h5>
        <div className="d-xl-flex align-items-center">
          <div className="user-avatar xl-avatar">
            <Image fluid rounded src={photo} />
          </div>
          <div className="file-field">
            <div className="d-flex justify-content-xl-center ms-xl-3">
              <div className="d-flex">
                <span className="icon icon-md">
                  <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                </span>
                <input type="file" />
                <div className="d-md-block text-start">
                  <div className="fw-normal text-dark mb-1">Choose Image</div>
                  <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const CounterWidget = (props) => {
  const { icon, iconColor, category, title, testDate, sponsorName } = props;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
              <FontAwesomeIcon icon={icon} />
            </div>
            <div className="d-sm-none">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
            <small>From test taken on {testDate}</small>
            <div className="small mt-2">
              <span className="fw-bold">
                Sponsored by {sponsorName}
              </span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const PsychometricCreditsWidget = (props) => {
  let [profileCredits, setProfileCredits] = useState(0);

  useEffect(()=>{
    SponsorCredits()
      .then((body)=>{
        setProfileCredits(body.credits);
      });
  }, []);

  return (
    <Card border="light" className="shadow-sm mb-2">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={3} sm={12} className=" d-flex align-items-center justify-content-center mb-3 mb-xl-0">
            <div className="text-center display-3  me-sm-0">
              {profileCredits}
            </div>
          </Col>
          <Col xl={9} sm={12} className="px-xl-0">
            <div className="text-center" >
              <h5>Profile Test Credits</h5>
              <Button as={Link} to={Routes.Products.path} type="primary" className="px-3 py-2 w-75">Buy</Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const FWSCreditsWidget = (props) => {
  let [fwsCredits, setFWSCredits] = useState(0);

  useEffect(()=>{
    SponsorCredits()
      .then((body)=>{
        setFWSCredits(body.fws_credits);
      });
  }, []);

  return (
    <Card border="light" className="shadow-sm mb-2">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={3} sm={12} className=" d-flex align-items-center justify-content-center mb-3 mb-xl-0">
            <div className="text-center display-3  me-sm-0">
              {fwsCredits}
            </div>
          </Col>
          <Col xl={9} sm={12} className="px-xl-0">
            <div className="text-center" >
              <h5>FWS Test Credits</h5>
              <Button as={Link} to={Routes.Products.path} type="primary" className="px-3 py-2 w-75">Buy</Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};


export const SubSponsorsWidget = () => {

  let [subSponsorsList, setSubSponsorsList] = useState([]),
      [loading, setLoading] = useState(false),
      [subSponsorAddCredits, setSubSponsorAddCredits] = useState({});


  const loaderCSS = css`
  display: inline-block;
`,

        handleClose = ()=>{
          setLoading(true);
          SubSponsorsList()
            .then((body)=>{
              setSubSponsorsList(body.sponsors.slice(0, 5));
            })
            .finally(()=>{
              setLoading(false);
            });
        };
  useEffect(()=>{
    handleClose();
  }, []);



  const SubSponsor = (props) => {
    const { name, available_credit, available_fws_credit, picture } = props;
    return (
      <ListGroup.Item className="px-0">
        <Row className="align-items-center">
          <Col className="col-auto">
            <div className="user-avatar">
              <Image src={picture} style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderRadius: '50%',
              }} />
            </div>
          </Col>
          <Col className="ms--2">
            <h4 className="h6 mb-0">
              {name}
            </h4>
            <span>● </span>
            <small>Profile: {parseFloat(available_credit).toFixed(0)}</small>
            <span>      ● </span>
            <small>FWS: {parseFloat(available_fws_credit).toFixed(0)}</small>
          </Col>
          <Col className="col-auto">
            <Button variant="secondary" size="sm" className="me-2" onClick={()=>setSubSponsorAddCredits(props)} >Transfer Credits</Button>

          </Col>
        </Row>
        <TransferCreditsModal subSponsor={subSponsorAddCredits} requestClose={() => {
          setSubSponsorAddCredits({});
          handleClose();
        }}/>
      </ListGroup.Item>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">Sub Sponsors</h5>
        <Button as={Link} variant="secondary" size="sm" to={Routes.SubSponsors.path}>See all</Button>
      </Card.Header>
      <Card.Body>
        {!loading && <ListGroup className="list-group-flush list my--3">
          {subSponsorsList.map(tm => <SubSponsor key={`team-member-${tm.account}`} {...tm} />)}
        </ListGroup>}
        {loading && <div className="w-100 text-center">
          <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
        </div>}
      </Card.Body>
    </Card>
  );
};


export const ProgressTrackWidget = () => {

  let [campaignDetailsList, setCampaignDetailsList] = useState([]),
      [loading, setLoading] = useState(false);


  const loaderCSS = css`
  display: inline-block;
`,
        getCampaigns = ()=>{
          setLoading(true);
          CampaignDetailsList()
            .then((body)=>{
              setCampaignDetailsList(body.tests);
            })
            .finally(()=>{
              setLoading(false);
            });
        };
  useEffect(()=>{
    getCampaigns();
    document.addEventListener('CAMPAIGN_CREATED', getCampaigns);
    return ()=>{
      document.removeEventListener('CAMPAIGN_CREATED', getCampaigns);
    };
  }, []);



  const Progress = (props) => {
    const { campaign, progress, type } = props,
          colors = ['purple', 'danger', 'tertiary', 'info'],
          color = colors[Math.floor(Math.random()*colors.length)],
          character = type === 'fws' ? 'F' :'P';
    return (
      <Row className={`align-items-center ${campaign}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <span className='red'>{character}</span>
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{campaign}</h6>
              <small className="fw-bold text-dark">
                <span>{progress.toFixed(2)} %</span>
              </small>
            </div>
            <ProgressBar variant={color} now={progress} min={0} max={100} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light">
        <h5 className="mb-0">Campaigns Progress Track</h5>
      </Card.Header>
      <Card.Body>
        {!loading && <ListGroup className="list-group-flush list my--3">
          {campaignDetailsList.map((tm, idx) => <Progress key={idx} {...tm} />)}
        </ListGroup>}
        {loading && <div className="w-100 text-center">
          <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
        </div>}
      </Card.Body>
    </Card>
  );
};



export const FWSRecentWidget = (props) => {
  let [fwsRecentTest, setFwsResult] = useState({});

  useEffect(()=>{
    FWSTests({
      from: 0,
      size: 1
    })
      .then((data)=>{
        let fwsResponse = _.get(data, 'tests.0'), fwsData={};
        if(!fwsResponse) {
          fwsData.score = '-';
          fwsData.h1 = 'You\'ve haven\'t taken any tests yet';
          fwsData.h2 = 'Buy tests or contact your sponsor';
        } else {
          fwsData.score = fwsResponse.score;
          fwsData.h1 = 'From test taken on ' + moment(fwsResponse.created_at).format('DD MMM YYYY');
          fwsData.h2 =  'Sponsored by ' + fwsResponse.name;
        }
        setFwsResult(fwsData);
      },
      () => {
        setFwsResult({});
      }
      );
  }, []);

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className="icon icon-shape icon-md icon-shape-tertiary rounded me-4 me-sm-0">
              <FontAwesomeIcon icon={faCashRegister} />
            </div>
            <div className="d-sm-none">
              <h5>FWS Result</h5>
              <h3 className="mb-1">{fwsRecentTest.score}</h3>
            </div>
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h5>FWS Score</h5>
              <h3 className="mb-1">{fwsRecentTest.score}</h3>
            </div>
            <small>{fwsRecentTest.h1}</small>
            <div className="small mt-2">
              <span className="fw-bold">
                {fwsRecentTest.h2}
              </span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const PendingTestsWidget = (props) => {
  const { icon, iconColor, category, title, testDate, sponsorName } = props;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="p-2 px-3">
        Pending Tests
      </Card.Header>
      <Card.Body>
        <Row className="d-flex align-items-center">
          <Col  className="px-xl-0 mb-3">
            <div className="text-center">
              <h5>Psychometrics</h5>
              <h3 className="mb-1">{10}</h3>
              <div className='d-flex justify-content-center'>
                <Button className="p-1 px-2 me-1 d-flex">Take Now<FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1 my-auto" size="xs"/></Button>
                <Button className="p-1 px-2">See All</Button>
              </div>
            </div>
          </Col>
          <Col className="px-xl-0 mb-3">
            <div className="text-center">
              <h5>FWS</h5>
              <h3 className="mb-1">{10}</h3>
              <div className='d-flex justify-content-center'>
                <Button className="p-1 px-2 me-1 d-flex">Take Now<FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1 my-auto" size="xs"/></Button>
                <Button className="p-1 px-2">See All</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const CircleChartWidget = (props) => {
  const { title, data = [] } = props,
        series = data.map(d => d.value);

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <CircleChart series={series} />
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <h5 className="mb-3">{title}</h5>

            {data.map(d => (
              <h6 key={`circle-element-${d.id}`} className="fw-normal text-gray">
                <FontAwesomeIcon icon={d.icon} className={`icon icon-xs text-${d.color} w-20 me-1`} />
                {` ${d.label} `}{`${d.value}%`}
              </h6>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const BarChartWidget = (props) => {
  const { title, value, percentage, data = [] } = props,
        labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        series = data.map(d => d.value),
        percentageIcon = percentage < 0 ? faAngleDown : faAngleUp,
        percentageColor = percentage < 0 ? 'text-danger' : 'text-success';

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
        <div className="d-block">
          <h6 className="fw-normal text-gray mb-2">{title}</h6>
          <h3>{value}</h3>
          <small className="mt-2">
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={`${percentageColor} fw-bold`}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-block ms-auto">
          {data.map(d => (
            <div key={`bar-element-${d.id}`} className="d-flex align-items-center text-end mb-2">
              <span className={`shape-xs rounded-circle bg-${d.color} me-2`} />
              <small className="fw-normal">{d.label}</small>
            </div>
          ))}
        </div>
      </Card.Body>
      <Card.Body className="p-2">
        <BarChart labels={labels} series={series} />
      </Card.Body>
    </Card>
  );
};

export const RankingWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
          <div>
            <h6><FontAwesomeIcon icon={faGlobeEurope} className="icon icon-xs me-3" /> Global Rank</h6>
          </div>
          <div>
            <Card.Link href="#" className="text-primary fw-bold">
              #755 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFlagUsa} className="icon icon-xs me-3" />Country Rank</h6>
            <div className="small card-stats">
              United States <FontAwesomeIcon icon={faAngleUp} className="icon icon-xs text-success ms-2" />
            </div>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #32 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFolderOpen} className="icon icon-xs me-3" />Category Rank</h6>
            <Card.Link href="#top" className="small card-stats">
              Travel &gt; Accomodation
            </Card.Link>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #16 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const SalesValueWidget = (props) => {
  const { title, value, percentage } = props,
        percentageIcon = percentage < 0 ? faAngleDown : faAngleUp,
        percentageColor = percentage < 0 ? 'text-danger' : 'text-success';

  return (
    <Card className=" shadow-sm">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-3">Week</Button>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart />
      </Card.Body>
    </Card>
  );
};
export const StripeConnectWidget = () => {


  return (
    <Card className="shadow-sm">
      <Card.Body className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            Stripe Account Status
          </h5>
          <h3 className="text-danger">Unconnected</h3>
          <div className="text-muted">You need to connect your account via Stripe in order to receive money as an affiliate.</div>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="success" size="md" className="">Connect</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export const SalesValueWidgetPhone = (props) => {
  const { title, value, percentage } = props,
        percentageIcon = percentage < 0 ? faAngleDown : faAngleUp,
        percentageColor = percentage < 0 ? 'text-danger' : 'text-success';

  return (
    <Card className="shadow-sm">
      <Card.Header className="d-md-flex flex-row align-items-center flex-0">
        <div className="d-block mb-3 mb-md-0">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-3">Week</Button>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChartphone />
      </Card.Body>
    </Card>
  );
};

export const AcquisitionWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <h5>Acquisition</h5>
        <p>Tells you where your visitors originated from, such as search engines, social networks or website referrals.</p>
        <div className="d-block">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Bounce Rate</label>
              <h4 className="mb-0">33.50%</h4>
            </div>
          </div>
          <div className="d-flex align-items-center pt-3">
            <div className="icon icon-shape icon-sm icon-shape-quaternary rounded me-3">
              <FontAwesomeIcon icon={faChartArea} />
            </div>
            <div className="d-block">
              <label className="mb-0">Sessions</label>
              <h4 className="mb-0">9,567</h4>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const GenerateTestLinkWidget = ({groupId, onSave}) => {
  const testOptions = [
    'Profile',
    'FWS'
  ],
        [loading, setLoading] = useState(false),
        [error, setError] = useState(false),
        [success, setSuccess] = useState(false),

        schema = Yup.object({
          campaign: Yup.string().required('Campaign name is Required').max(200, 'Campaign name cannot exceed 200 characters'),
          credits: Yup.number().min(0, 'Credits cannot be negative').required('Credits are required'),
          test_type: Yup.string().oneOf(testOptions.map(i => i.toLowerCase()), 'Test Type is required')
        }),
        validate = async (values) => {
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
          }
        },

        submit = async (body)=>{
          setLoading(true);
          try{
            let data;
            if(groupId) {
              data = await CreateGroupLink({
                group_id: groupId,
                ...body
              });
            } else {
              data = await GenerateLink(body);
            }

            if(!data.success){
              setError(data.error || 'Unknown error occurred. Please try again');
              return {[FORM_ERROR]: data.error || 'Unknown error occurred. Please try again'};
            }
            setSuccess(true);
            copy(data.test_link, {format: 'text/plain'});
            toast.success('Campaign Created Successfully');
            setTimeout(()=>setSuccess(false), 2500);
            const event = new Event('CAMPAIGN_CREATED');
            document.dispatchEvent(event);
          } catch(err){
            setError('Unknown error occurred. Please try again');
            console.error(err);
            return {[FORM_ERROR]: 'Unknown error occurred. Please try again'};
          } finally{
            onSave && onSave();
            setLoading(false);
          }
        };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        {error &&
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
        }
        <FormHandler onSubmit={submit}
          validate={validate}
          render={({handleSubmit, hasValidationErrors, form: {restart}})=>(
            <Form onSubmit={async(event)=> {
              let error = await handleSubmit(event);
              if(error){
                return error;
              } else {
                restart();
              }
            }}>
              <h5>Generate Test Link</h5>
              <Row className="d-block d-xl-flex align-items-center">
                <Col className="mb-3">
                  <Form.Group id="Account ID">
                    <Form.Label>Campaign Name</Form.Label>
                    <Field name='campaign' render={({input, meta})=>(<>
                      <Form.Control required isInvalid={meta.touched && meta.error} type="text" placeholder="Campaign Name" {...input} />
                      {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                    </>)}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col sm={6} lg={4} className="mb-3">
                  <Form.Group id="Credits">
                    <Form.Label>Credits</Form.Label>
                    <Field name='credits' render={({input, meta})=>(<>
                      <Form.Control required isInvalid={meta.touched && meta.error} type="number" placeholder="0" {...input}/>
                      {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                    </>)}/>
                  </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-3">
                  <Form.Group id="Test Type">
                    <Form.Label>Test Type</Form.Label>
                    <Field name='test_type' options={testOptions} defaultValue={-1} component="select">
                      {
                        ({input, meta, options})=>(
                          <>
                            <Form.Control as="select" onChange={input.onChange} required isInvalid={meta.touched && meta.error} placeholder='Choose' {...input}>
                              <option value={-1} disabled>Choose</option>
                              {options.map((option)=>(
                                <option key={option} value={option.toLowerCase()}>{option}</option>
                              ))}

                            </Form.Control>
                          </>
                        )
                      }
                    </Field>
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4} className="mb-3 mt-auto">
                  {!success && <Button variant="primary" disabled={loading || hasValidationErrors} type="submit"
                    className="w-100 mb-0 d-inline-flex justify-content-center align-items-center">
                    Create{loading &&
                  <div className='ms-2 d-inline-flex align-items-center'><RingLoader loading={loading} color="white"
                    size={17}/></div>}
                  </Button>}
                  {success && <Button variant="success" className='w-100 mb-0 d-inline-flex text-center align-items-center justify-content-center'>Link Copied<FontAwesomeIcon icon={faCheckCircle} className="ms-1" /></Button>}
                </Col>
              </Row>
            </Form>)}/>
      </Card.Body>
    </Card>
  );
};
