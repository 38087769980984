import React, {
  useEffect, useRef, useState
} from 'react';
import moment from 'moment';
import {
  Button, Card, Image, Table, Tabs, Tab, Row, Col, Modal
} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faDownload, faEye, faPaperPlane, faPlusCircle, faQrcode, faTrash
} from '@fortawesome/free-solid-svg-icons';
import {css} from '@emotion/react';
import {RingLoader} from 'react-spinners';
import {
  Link, useParams
} from 'react-router-dom';
import {
  GroupDetails,
  GroupInvitations,
  GroupLinks,
  GroupMemberList,
  GroupResourcesService,
  ResendInvitation,
  ReSendInvitation,
  sendInvitation
} from '../../services/GroupServices';
import {
  EditGroupDetails, GroupInfoForm
} from './widgets';
import {toast} from 'react-toastify';
import {GenerateOrder} from '../../services/PaymentsService';
import {captureException} from '@sentry/react';
import {Routes} from '../../routes';
import {
  DeleteGroupModal, DeleteInvitationModal, DeleteResourceModal, InviteMembersModal, UploadResourceModal
} from './modals';
import {
  ProfileTestLinks, ProfileTestLinksCount
} from '../../services/ReportService';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  faCheckCircle, faClipboard
} from '@fortawesome/free-regular-svg-icons';
import {CreateGroupTestLinkModal} from './modals';
import _ from 'lodash';
import QRCode from 'react-qr-code';
import {
  AddProfileCreditModal, DeleteProfileLinkModal
} from '../ProfileLinks/modals';
import {
  AddFwsCreditModal, DeleteFwsLinkModal
} from '../FWSLinks/modals';

const loaderCSS = css`
  display: inline-block;
`;

function GroupMembersTable ({group_id}) {
  const TableRow = (user) => {
    // const { campaign, type, created_at, id, user } = props;

    return (
      <tr>
        <td scope="row"><u><Link to={`/test-taker-info/${user.id}`}>{user.name}</Link></u></td>
        <td className="fw-bold">
          {user.email}
        </td>
        <td>
          {user.age}
        </td>
        <td>
          {user.income}
        </td>
        <td>
          <Button className="p-2 py-1 btn-danger" onClick={() => {
            // setDeleteLink(props);
          //  TODO
          }}><FontAwesomeIcon
              icon={faTrash}/></Button>        </td>
      </tr>
    );
  },

        [data, setData] = useState([]),
        [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    GroupMemberList({group_id})
      .then((data)=>{
        setData(data.test_takers);
      })
      .finally(()=>setLoading(false));
  }, [group_id]);

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="">
        {loading?
          <div className="w-100 text-center">
            <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
          </div>
          :
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                {/*<th className="border-0">Image</th>*/}
                <th className="border-0">Name</th>
                <th className="border-0">Email</th>
                <th className="border-0">Age Range</th>
                <th className="border-0">Income</th>
                <th className="border-0">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
            </tbody>
          </Table>}
      </Card.Body>
    </Card>
  );
}
function GroupLinksTable () {
  let [testLinks, setTestLinks] = useState([]),
      [qrCodeLink, setQRCodeLink] = useState(),
      [deleteLink, setDeleteLink] = useState({}),
      [addCreditLinkId, setAddCreditLinkId] = useState({}),
      [loading, setLoading] = useState(false),
      [showCreateLinkModal, setShowCreateLinkModal] = useState(false),
      {groupId: group_id} = useParams();

  const TableRow = (props) => {
    const {  type, test_link: {campaign, credits}, test_url, id, user } = props;
    let [copy, setCopy] = useState(false);

    return (
      <tr>
        <td>
          {campaign}
        </td>
        <td>{_.capitalize(type)}</td>
        <td>{credits}<FontAwesomeIcon className="ms-2" icon={faPlusCircle} size="lg" onClick={() => {
          setAddCreditLinkId(props);
        }}/></td>
        <td><CopyToClipboard text={test_url} onCopy={() => {
          setCopy(true);
          setTimeout(()=>setCopy(false), 1000);
        }}><Button
            variant={copy ? 'success' : 'primary'} className="p-2 py-1"
            onBlur={() => setCopy(false)}>{copy ? 'Link Copied' : 'Copy Link'} <FontAwesomeIcon
              icon={copy ? faCheckCircle : faClipboard} className="ms-1"/></Button></CopyToClipboard></td>
        <td><Button className="p-2 py-1 btn-primary" onClick={() => {
          setQRCodeLink(test_url);
        }}>QR Code<FontAwesomeIcon
            icon={faQrcode} className="ms-1"/></Button></td>
        <td><Button className="p-2 py-1 btn-danger" onClick={() => {
          setDeleteLink(props);
        }}><FontAwesomeIcon
            icon={faTrash}/></Button></td>
      </tr>
    );
  },

        searchData = ()=>{
          setLoading(true);
          GroupLinks({group_id})
            .then((data)=>{
              setTestLinks(data.group_test_links);
            })
            .finally(()=>setLoading(false));
        };

  useEffect(()=>{
    searchData();
  }, [group_id]);

  return (
    <>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Header>
          <Row className="align-items-center">
            <Col className="text-end flex-nowrap" >
              <Button variant="secondary" size="sm" onClick={()=>setShowCreateLinkModal(true)}>Create Test Link</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {loading?
            <div className="w-100 text-center">
              <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
            </div>
            :
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0 w-100">Campaign</th>
                  <th className="border-0">Type</th>
                  <th className="border-0">Credits</th>
                  <th colSpan={3} className="border-0 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {testLinks.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
              </tbody>
            </Table>}
        </Card.Body>
      </Card>
      {showCreateLinkModal && <CreateGroupTestLinkModal requestClose={()=> {
        setShowCreateLinkModal(false);
        setLoading(true);
        GroupLinks({group_id})
          .then((data)=>{
            setTestLinks(data.group_test_links);
          })
          .finally(()=>setLoading(false));
      }}/>}
      <Modal as={Modal.Dialog} centered show={qrCodeLink} onHide={() => setQRCodeLink('')}>
        <Modal.Header>
          <Modal.Title className="h6">QR Code</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setQRCodeLink('')}/>
        </Modal.Header>
        <Modal.Body className="text-center">
          <QRCode value={qrCodeLink}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="text-gray ms-auto" onClick={() => setQRCodeLink('')}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {deleteLink.type === 'profile' && <DeleteProfileLinkModal link={{
        uuid: deleteLink.test_link.uuid,
        test_link: deleteLink.test_url
      }} requestClose={() => {
        setDeleteLink({});
        searchData();
      }}/>}
      {deleteLink.type === 'fws' && <DeleteFwsLinkModal link={{
        uuid: deleteLink.test_link.uuid,
        test_link: deleteLink.test_url
      }} requestClose={() => {
        setDeleteLink({});
        searchData();
      }}/>}
      {addCreditLinkId.type === 'profile' && <AddProfileCreditModal link={{
        uuid: addCreditLinkId.test_link.uuid,
        credits: addCreditLinkId.test_link.credits
      }} requestClose={() => {
        setAddCreditLinkId({});
        searchData();
      }}/>}
      {addCreditLinkId.type === 'fws' && <AddFwsCreditModal link={{
        uuid: addCreditLinkId.test_link.uuid,
        credits: addCreditLinkId.test_link.credits
      }} requestClose={() => {
        setAddCreditLinkId({});
        searchData();
      }}/>}
    </>
  );
}

function GroupResourcesTable () {
  let [groupResources, setGroupResources] = useState([]),
      [qrCodeLink, setQRCodeLink] = useState(),
      [deleteResource, setDeleteResource] = useState({}),
      [addCreditLinkId, setAddCreditLinkId] = useState({}),
      [loading, setLoading] = useState(false),
      [showAddResourceModal, setShowAddResourceModal] = useState(false),
      {groupId: group_id} = useParams();

  const TableRow = (props) => {
    const { title, file } = props;

    return (
      <tr>
        <td>
          {title}
        </td>
        {/* TODO: Replace with custom preview modal, similar to google drive*/}
        <td><Button href={file} target="_blank" className="p-2 py-1 btn-primary">Preview<FontAwesomeIcon
          icon={faEye} className="ms-1"/></Button></td>
        <td><Button className="p-2 py-1 btn-danger" onClick={() => {
          setDeleteResource(props);
        }}><FontAwesomeIcon
            icon={faTrash}/></Button></td>
      </tr>
    );
  },

        searchData = ()=>{
          setLoading(true);
          GroupResourcesService({group_id})
            .then((data)=>{
              setGroupResources(data.resources);
            })
            .finally(()=>setLoading(false));
        };

  useEffect(()=>{
    searchData();
  }, [group_id]);

  return (
    <>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Header>
          <Row className="align-items-center">
            <Col className="text-end flex-nowrap" >
              <Button variant="secondary" size="sm" onClick={()=>setShowAddResourceModal(true)}>Add Resource</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {loading?
            <div className="w-100 text-center">
              <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
            </div>
            :
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0 w-100">Title</th>
                  <th className="border-0">View</th>
                  <th className="border-0">Delete</th>
                </tr>
              </thead>
              <tbody>
                {groupResources.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
              </tbody>
            </Table>}
        </Card.Body>
      </Card>
      {showAddResourceModal && <UploadResourceModal groupId={group_id} requestClose={()=> {
        setShowAddResourceModal(false);
        searchData();
      }}/>}
      {!_.isEmpty(deleteResource) && <DeleteResourceModal resourceId={deleteResource.id} requestClose={()=> {
        setDeleteResource({});
        searchData();
      }}/>}
    </>
  );
}

export function GroupInvitationsTable({groupId}){
  let [groupInvitations, setGroupInvitations] = useState([]),
      [inviteMembers, setInviteMembers] = useState(false),
      [deleteInvitation, setDeleteInvitation] = useState(),
      [loading, setLoading] = useState(false);

  const search = ()=>{
    setLoading(true);
    GroupInvitations({groupId})
      .then((data)=>{
        setGroupInvitations(data.invitations);
      })
      .finally(()=>setLoading(false));
  };
  useEffect(()=>{
    // TODO: Add error handling and not found handling
    search();
  }, []);

  const TableRow = (props) => {
    const { email, type, created_at, id, user } = props;
    let  [invitationLoading, setInvitationLoading] = useState(false);
    return (
      <tr>
        <td className="fw-bold w-100 align-middle">
          {email}
        </td>
        <td className="align-middle">
          {moment(created_at).format('DD MMM YYYY')}
        </td>
        <td>
          <div>
            <Button className='p-2 py-1' onClick={()=>{
              setInvitationLoading(true);
              ResendInvitation({invitationId: id})
                .then((data)=>{
                  if(data.success){
                    toast.success('Successfully resend invitation');
                    return;
                  }
                  toast.error(data.message || 'Unknown error occurred');
                })
                .finally(()=>setInvitationLoading(false));
            } }><FontAwesomeIcon icon={faPaperPlane} size='xs' className='me-2' />Resend {invitationLoading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={invitationLoading} color="white" size={17} /></div> }</Button>
          </div>
        </td>
        <td><Button className="p-2 py-1 btn-danger" onClick={() => setDeleteInvitation(props)}><FontAwesomeIcon
          icon={faTrash}/></Button></td>
      </tr>
    );
  };


  return (
    <>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Header>
          <Row className="align-items-center">
            <Col className="text-end flex-nowrap" >
              <Button variant="secondary" size="sm" onClick={() => setInviteMembers(true)} className="me-2">Invite Member +</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="">
          {loading?
            <div className="w-100 text-center">
              <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
            </div>
            :
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Email</th>
                  <th className="border-0">Sent On</th>
                  <th className="border-0">Resend</th>
                  <th className="border-0">Delete</th>
                </tr>

              </thead>
              <tbody>
                {groupInvitations.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
              </tbody>
            </Table>}
        </Card.Body>
        {inviteMembers && <InviteMembersModal groupId={groupId} requestClose={() => {
          setInviteMembers(false);
          search();
        }}/>}
      </Card>
      {deleteInvitation && <DeleteInvitationModal invitationId={deleteInvitation.id} requestClose={() => {
        setDeleteInvitation();
        search();
      }}/>}
    </>
  );
}

export default function GroupMembers () {
  let [groupDetails, setGroupDetails] = useState({}),
      {groupId} = useParams(),
      [loading, setLoading] = useState(false),
      containerRef = useRef(),
      [containerHeight, setContainerHeight] = useState(0);

  useEffect(()=>{
    // TODO: Add error handling and not found handling
    setLoading(true);
    GroupDetails({groupId})
      .then((data)=>{
        setGroupDetails(data.group);
      })
      .finally(()=>setLoading(false));
  }, []);

  useEffect(()=>{

    let observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.contentBoxSize){
          setContainerHeight(entry.contentRect.width);
        }
      }
    });
    observer.observe(containerRef.current);
    setContainerHeight(containerRef.current.offsetWidth);
    return ()=>observer.disconnect();
  }, [groupDetails]);

  return (
    <>
      <Row className="py-4">
        <Col lg={2} ref={containerRef} >
          <Image  style={{
            minWidth: '100px',
            height: `${containerHeight}px`,
            objectFit: 'contain'
          }} fluid roundedCircle src={groupDetails.image} />
        </Col>
        <Col lg={10} className="mb-4 mb-xl-0">
          <h4>{groupDetails.name}</h4>
          <p>{groupDetails.description}</p>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="members"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab eventKey="members" title="Members">
          <GroupMembersTable group_id={groupId}/>
        </Tab>
        <Tab eventKey="test-links" title="Test Links">
          <GroupLinksTable />
        </Tab>
        <Tab eventKey="resources" title="Resources">
          <GroupResourcesTable />
        </Tab>
        <Tab eventKey="invitations" title="Invitations">
          <GroupInvitationsTable groupId={groupId}/>
        </Tab>
        <Tab eventKey="group-settings" title="Settings">
          <GroupInfoForm groupInfo={groupDetails} groupId={groupId}/>
        </Tab>
      </Tabs>

    </>
  );
}
