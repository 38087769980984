import React, {useState} from 'react';
import {Button, Card, Table} from '@themesberg/react-bootstrap';
import {DeleteSponsorModal, TransferCreditsModal} from './modals';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from '@emotion/react';
import {RingLoader} from 'react-spinners';

const loaderCSS = css`
  display: inline-block;
`;

export function SubSponsorsTable({subSponsors, loading, onChange}) {
  let [subSponsorAddCredits, setSubSponsorAddCredits] = useState({}),
      [subSponsorDelete, setSubSponsorDelete] = useState({});
  const TableRow = (props) => {
    const {name, email, available_credit, available_fws_credit, role} = props;

    return (
      <tr className="align-middle">
        <td>{name}</td>
        <td>{email}</td>
        <td>{parseInt(available_credit)}</td>
        <td>{parseInt(available_fws_credit)}</td>
        <td>{role}</td>
        <td>
          <Button variant={'primary'} className={'p-2 py-1'} onClick={()=>setSubSponsorAddCredits(props)}>Transfer Credits</Button>
        </td>
        <td>
          <Button variant={'danger'} className={'p-2 py-1'} onClick={()=>setSubSponsorDelete(props)}><FontAwesomeIcon
            icon={faTrash}/></Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          {loading?
            <div className="w-100 text-center">
              <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
            </div>
            :
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Profile Credits</th>
                  <th className="border-0">FWS Credits</th>
                  <th className="border-0">Sponsor Type</th>
                  <th colSpan={2} className="border-0 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {subSponsors.map(pt => <TableRow key={`page-traffic-${pt.account}`} {...pt} />)}
              </tbody>
            </Table>}
        </Card.Body>
      </Card>
      <TransferCreditsModal subSponsor={subSponsorAddCredits} requestClose={() => {
        setSubSponsorAddCredits({});
        onChange();
      }}/>
      <DeleteSponsorModal subSponsor={subSponsorDelete} requestClose={() => {
        setSubSponsorDelete({});
        onChange();
      }}/>
    </>
  );
}
