
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart, faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell, faUserCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  Row, Col, Nav, Image, Navbar, Dropdown, Container, ListGroup
} from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from '../data/notifications';
import getStore from '../stores';
import {Routes} from '../routes';
import {
  Link, useHistory
} from 'react-router-dom';
import Avatar from 'react-avatar';
import {observer} from 'mobx-react';
import getSymbolFromCurrency from 'currency-symbol-map';

function Header (props) {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA),
        areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true),

        markNotificationsAsRead = () => {
          setTimeout(() => {
            setNotifications(notifications.map(n => ({
              ...n,
              read: true
            })));
          }, 300);
        };

  let history = useHistory(),
      store = getStore('CartStore');

  const CartItem = (props) => {
    const { productId, quantity, productName, productImage, cost, currency} = props;
    return (
      <ListGroup.Item action as={Link} to={`/products/${productId}`} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col sm={3} >
            <Image src={productImage} />
          </Col>
          <Col sm={9} className="ps-0">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{productName}</h4>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="mt-1 mb-0">x{quantity}</div>
              <div className="mt-1 mb-0 text-black">US{getSymbolFromCurrency(currency)} {cost}</div>
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div className="display-4">Sponsor Dashboard</div>
          </div>
          <Nav className="align-items-center">
            {/*<Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >*/}
            {/*  <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">*/}
            <Link to="/cart">
              <span className="icon icon-sm me-lg-3 icon-notifications position-relative">
                <FontAwesomeIcon icon={faShoppingCart} className="bell-shake" />
                {store.cartContents.length === 0 ? null : <span className="icon-badge rounded-circle unread-notifications" />}
              </span>
            </Link>
            {/*</Dropdown.Toggle>*/}
            {/*</Dropdown>*/}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="py-1 px-0">
                <div className="media d-md-flex d-none align-items-center">
                  <Avatar name={getStore('UserStore').data.name} src={getStore('UserStore').data.profile} round={true} size={40}/>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{getStore('UserStore').data.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" as={Link} to={Routes.Profile.path}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={()=>{
                  getStore('UserStore').logout();
                  history.push(Routes.Signin.path);
                }}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}

export default observer(Header);
