import HTTPService from './HTTPService';

export async function CreateGroupService({name, description, image, emails}){
  let body = new FormData();
  body.append('name', name);
  body.append('description', description);
  for (const email in emails) {
    body.append('members[]', emails[email]);
  }

  body.append('image', image[0], image[0].name);
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group`,
    method: 'POST',
    body
  });
}

export async function InviteMembersService({emails, groupId}){
  let body = new FormData();
  for (const email in emails) {
    body.append('members[]', emails[email]);
  }

  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/invite-members/${groupId}`,
    method: 'POST',
    body
  });
}

export async function GroupsList(){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group-list`, });
}

export async function GroupMemberList({group_id}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${group_id}/members`, });
}

export async function GroupDetails({groupId}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${groupId}`, });
}

export async function GroupInvitations({groupId}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${groupId}/invitations`, });
}

export async function DeleteGroup({groupId}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/delete-group/${groupId}`,
    method: 'DELETE'
  });
}

export async function DeleteInvitationService({invitationId}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/delete-invitation/${invitationId}`,
    method: 'DELETE'
  });
}

export async function DeleteResourceService({groupId, resourceId}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${groupId}/resource/${resourceId}`,
    method: 'DELETE'
  });
}

export async function UpdateGroupDetails({groupId, name, description, image}){

  let body = new FormData();
  body.append('name', name);
  body.append('description', description);
  if(image[0] instanceof Blob){
    body.append('image', image[0], image[0].name);
  }

  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/update-group-details/${groupId}`,
    method: 'PUT',
    body: body
  });
}


export async function sendInvitation(groupId, userEmail, resendFlag){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/send-invitation`,
    method: 'GET',
    json: {
      groupId,
      userEmail,
    }
  });
}
export async function ResendInvitation({invitationId}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/resend-invitation/${invitationId}`, });
}


export async function GroupLinks({group_id}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${group_id}/links`, });
}

export async function CreateGroupLink({group_id, credits, campaign, test_type}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${group_id}/links/${test_type}`,
    method: 'POST',
    json: {
      credits,
      campaign,
    }
  });
}

export async function GroupResourcesService({group_id}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${group_id}/resource`, });
}

export async function CreateGroupResourcesService({group_id, file, title}){
  let body = new FormData();
  body.append('title', title);
  body.append('file', file, file.name);
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/${group_id}/resource`,
    method: 'POST',
    body
  });
}

