import HTTPService from './HTTPService';

export async function SponsorProfile(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/details`
  });
}

export async function UpdateSponsorProfile({name, qualification, handphone_number, profile_link, title, company, reviewOne, reviewTwo, downloadLink, telegram}){
  return HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/details`,
    method: 'POST',
    json: {
      name,
      qualification,
      handphone_number,
      profile_link,
      title,
      company,
      reviewOne,
      reviewTwo,
      downloadLink,
      telegram
    }
  });
}

export async function UploadSponsorProfileImages({type, image, fileName}){
  let formData = new FormData();
  if(fileName) formData.append(type, image, fileName);
  else formData.append(type, image);

  return HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/details`,
    method: 'POST',
    body: formData,
    options: {raw: true}
  });
}
export async function SponsorCredits(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/credits`
  });
}
