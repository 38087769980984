import HTTPService from './HTTPService';

export async function ProductList(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/payments/products`});
}
export async function ProductDetail(productId){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/payments/products/${productId}`});
}

export async function ProductsDetails(productIds){
  const searchParams = new URLSearchParams({ids: productIds.join()});

  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/payments/products?${searchParams.toString()}`});
}

export async function EstimateCartCost({pricing, coupon_code}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/payments/estimate-cost`,
    method: 'POST',
    json: {
      pricing,
      coupon_code
    }
  });
}

export async function GenerateOrder(request){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/payments/generate-client-secret`,
    method: 'POST',
    json: request
  });
}
