import {
  makeObservable, observable, computed, action
} from 'mobx';
import jsonwebtoken from 'jsonwebtoken';
import ChatStore from './ChatStore';

class UserStore {
  jwt = ''
  expiry_listener = ()=>{}
  constructor() {
    makeObservable(this, {
      jwt: observable,
      isAuthenticated: computed,
      logout: action,
      initialise: action,
      data: computed
    });
    this.initialise();
  }

  initialise() {
    this.jwt = localStorage.getItem('sponsor_token');
    if (this.data.exp < (Date.now() /1000)){
      this.logout();
    } else{
      this.expiry_listener = setTimeout(()=>{
        this.logout();
      }, ((this.data.exp - (Date.now() /1000))*1000));
    }
    if (this.isAuthenticated) {
      return new Promise(((resolve, reject) => {
        setTimeout(() => {
          // ChatStore.initialise();
          resolve();

        }, 0);
      }));
    }
  }

  get data() {
    return (this.jwt && jsonwebtoken.decode(this.jwt)) || {};
  }

  get isAuthenticated() {
    return this.jwt;
  }

  get userType (){
    if (this.isAuthenticated){
      return this.data.user_type;
    }
    return undefined;
  }
  get isTokenValid() {
    return this.isAuthenticated && this.data.exp && this.data.exp >  (Date.now() /1000);
  }

  logout() {
    clearTimeout(this.expiry_listener);
    localStorage.removeItem('sponsor_token');
    localStorage.removeItem('sponsor_cart');
    this.jwt = '';
  }
}

export default new UserStore();
