import React, {
  useEffect, useState
} from 'react';
import {observer} from 'mobx-react';
import {
  Alert,
  Button,
  Card, Col, Container, Form, FormControl, FormGroup, InputGroup, Modal, Row
} from '@themesberg/react-bootstrap';
import getStore from '../../stores';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faInbox,
  faMinusCircle, faPlusCircle, faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import {RingLoader} from 'react-spinners';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {
  CardElement, Elements, useElements, useStripe
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import getSymbolFromCurrency from 'currency-symbol-map';
import {autorun} from 'mobx';
import _ from 'lodash';
import {
  EstimateCartCost, GenerateOrder
} from '../../services/PaymentsService';
import {css} from '@emotion/react';
import Skeleton from 'react-loading-skeleton';
import {
  Redirect, useHistory
} from 'react-router-dom';
import {Routes} from '../../routes';
import {captureException} from '@sentry/react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_SECRET),

      loaderCSS = css`
  display: inline-block;
`;

function CartItem({productImage, productName, quantity, cost, pricingId, currency}){
  const store = getStore('CartStore'),
        storeItem = store.cartContents.find((item)=>item.pricingId === pricingId);
  if (!storeItem){
  //  quantity doesn't exist in the store. Most likely the object doesn't exist as well. return null
    return null;
  }
  
  return <Card className="p-3 mb-4">
    <Row>
      <Col sm={2}>
        <Card.Img src={productImage}/>
      </Col>
      <Col >
        <Card.Title>{productName}</Card.Title>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faMinusCircle} size="lg" onClick={() => {
              store.changeQuantity(pricingId, Math.max(0, storeItem.quantity - 1));
            }}/>
            <Form.Control className="mx-2 text-center" as="input" value={storeItem.quantity} size="sm" htmlSize={3}
              style={{width: 'unset'}} onChange={(e) => {
                !Number.isNaN(parseInt(e.target.value)) && store.changeQuantity(pricingId, parseInt(e.target.value));
              }}/>
            <FontAwesomeIcon icon={faPlusCircle} size="lg" onClick={() => {
              store.changeQuantity(pricingId, storeItem.quantity + 1);
            }}/>
          </div>
        </div>
      </Col>
      <Col className="d-flex flex-grow-0 justify-content-center align-items-center">
        <h1 className="mb-0 me-4 text-nowrap">US{getSymbolFromCurrency(currency)} {quantity=== storeItem.quantity ?cost:<Skeleton width={100}/>}</h1>
        <FontAwesomeIcon icon={faTimes} style={{cursor: 'pointer'}} onClick={()=>{
          store.removeItem(pricingId);
        }}/>
      </Col>
    </Row>
  </Card>;
}


const ObserverCartItem = observer(CartItem);

function Cart(){
  const store = getStore('CartStore'),
        [loading, setLoading] = useState(false),
        [loadingEst, setLoadingEst] = useState(0),
        [couponCode, setCouponCode] = useState(),
        [cartData, setCartData] = useState({product_list: []}),
        onDiscountSubmit = (body)=>{

          setLoading(true);

          let products = _(store.cartContents).keyBy('pricingId').mapValues('quantity').value();
          return EstimateCartCost({
            pricing: products,
            coupon_code: body.coupon
          })
            .then((data) => {
              let couponMessage = data.coupon_message;
              if(couponMessage.length !== 0){
                return {coupon: couponMessage};
              } else {
                setCouponCode(body.coupon);
              }
            })
            .finally(()=>setLoading(false));
        };
  let [processing, setProcessing] = useState(false),
      [succeeded, setSucceeded] = useState(false),
      [error, setError] = useState(false),
      history = useHistory();

  const handleErrorModalClose = () =>{
    setError(false);
  },
        handleSuccessModalClose = () =>{
          store.clearCart();
          history.push(Routes.Orders.path);
        },
  
  
        stripe = useStripe(),
        elements = useElements(),
        handlePaymentSubmit = async (event) => {
          // Block native form submission.
          event.preventDefault();
          setProcessing(true);

          if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
          }

          // Make request to backend to fetch client secret
          const request = {
            pricing: store.cartContents.map(({pricingId, quantity})=>({
              quantity,
              pricing_id: pricingId
            })),
            coupon_code: couponCode
          };
          
          let client_secret = null;
          try{
            client_secret = (await GenerateOrder(request)).client_secret;
          } catch (e){
            setError('Payment failed due to unknown error. Please try again');
            setProcessing(false);
            captureException(e);
            return;
          }

          const payload = await stripe.confirmCardPayment(client_secret, {payment_method: {card: elements.getElement(CardElement)}});
          if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
          } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            console.log(payload);
          }
        },
        estimateFunc = _.debounce((products, couponCode)=> {
          EstimateCartCost({
            pricing: products,
            coupon_code: couponCode
          })
            .then((data) => {
              setCartData(data);
            })
            .finally(()=>setLoadingEst(false));
        }, 1000);

  useEffect(()=>autorun(()=> {
    setLoadingEst(true);
    let products = _(store.cartContents).keyBy('pricingId').mapValues('quantity').value();
    estimateFunc(products, couponCode);
  }), [couponCode]);

  return (<>
    <Row>
      <Col lg={8}>
        <Row>
          <Col>
            {cartData.product_list.length === 0 && !!loadingEst && <div className="w-100 text-center">
              <RingLoader css={loaderCSS} className="d-inline-block" loading={true}/>
            </div>
            }
            {store.cartContents.length === 0 && !loadingEst && <Alert variant="warning" className="text-center">Cart is Empty</Alert>}
            {store.cartContents.length >= 0 && cartData.product_list.length >= 0 && _.intersectionWith(cartData.product_list, store.cartContents, (a, b) => a.pricingId === b.pricingId).map((item) =>
              <ObserverCartItem key={item.pricingId} {...item}
              />)}

          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Title className="p-4 mb-0 pb-0">Payment Details</Card.Title>
              <Card.Body>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup className="mb-4">
                        <Form.Label>Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control disabled value={getStore('UserStore').data.name}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faInbox} />
                          </InputGroup.Text>
                          <Form.Control disabled value={getStore('UserStore').data.email}/>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Form.Label>Card Details</Form.Label>
                        <div style={{
                          borderRadius: '8px',
                          border: '0.0625rem solid #d1d7e0',
                          padding: '8px 12px'
                        }}>
                          <CardElement options={{style: {base: {lineHeight: '25px'}}}} />
                        </div>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Container className="justify-content-center d-flex">
                  <Button className='w-50' disabled={!stripe || processing || loadingEst || store.cartContents.length === 0} onClick={handlePaymentSubmit}>Pay ${cartData.total_amount} {processing  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={processing} color="white" size={17} /></div> }</Button>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col lg={4}>
        <Card className="p-1 position-sticky" style={{top: '1.5rem'}}>
          <Card.Body>
            <Card.Title>
            Total
            </Card.Title>
            <div>Sub Total: US${ !loadingEst ? cartData.sub_total: <Skeleton width={30}/> }</div>
            <div>Discount: US${!loadingEst?cartData.discount: <Skeleton width={30}/>}</div>
            <div>Total: US${!loadingEst ? cartData.total_amount: <Skeleton width={30}/>}</div>
            {couponCode && <div>Applied Coupon: {couponCode}</div>}
          </Card.Body>
          <Card.Body className="pt-0">
            <FormHandler
              onSubmit={onDiscountSubmit}
              render={({handleSubmit, pristine})=>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="discount-coupon">
                    <Form.Label>Discount Coupon</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='coupon' render={({input, meta})=>(<>
                        <Form.Control isInvalid={meta.touched && meta.submitError} type="text" autoFocus {...input}/>
                        {meta.submitError && <FormControl.Feedback type="invalid">{meta.submitError}</FormControl.Feedback>}
                      </>)}/>
                    </InputGroup>
                  </Form.Group>
                  <div className="mt-3">
                    <Button variant="primary" disabled={loading || pristine} type="submit" className="w-100  d-inline-flex justify-content-center align-items-center">
                    Apply{loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }
                    </Button>
                  </div>
                </Form>}/>
          </Card.Body>
        </Card>
      </Col>
    </Row>

    <Modal as={Modal.Dialog} centered show={error} onHide={handleErrorModalClose}>
      <Modal.Header>
        <Modal.Title className="h6">Payment Error</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleErrorModalClose} />
      </Modal.Header>
      <Modal.Body>
        <p>{error}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleErrorModalClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal as={Modal.Dialog} centered show={succeeded} onHide={handleSuccessModalClose}>
      <Modal.Header>
        <Modal.Title className="h6">Payment Successful</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleSuccessModalClose} />
      </Modal.Header>
      <Modal.Body>
        <p>Your payment was processed successfully. Head over to my orders for more details</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSuccessModalClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
    
  </>);
}

export default observer((props)=><Elements stripe={stripePromise}><Cart {...props}/></Elements>);
