
import React, {useState} from 'react';
import {Col, Row, Form, Card, Button, Container, InputGroup, FormControl} from '@themesberg/react-bootstrap';
import {Link, useHistory} from 'react-router-dom';

import { Routes } from '../../routes';
import * as Yup from 'yup';
import {setIn} from 'final-form';
import {Field, Form as FormHandler} from 'react-final-form';
import {ForgotPasswordService} from '../../services/AuthenticationServices';
import {RingLoader} from 'react-spinners';

const schema = Yup.object({
  email: Yup.string().email().required('Email is Required'),
}),
      validate = async (values) => {
        try {
          await schema.validate(values, { abortEarly: false });
        } catch (err) {
          const errors = err.inner.reduce((formError, innerError) => {
            return setIn(formError, innerError.path, innerError.message);
          }, {});
          return errors;
        }
      };


export default () => {
  let [error, setError] = useState(''),
      [success, setSuccess] = useState(''),
      [loading, setLoading] = useState(false),
      history = useHistory();

  const submit = ({email})=>{
    setError(false);
    setLoading(true);
    ForgotPasswordService({email})
      .then((resp)=>{
        if(resp.error){
          setError(resp.message);
        }
        else{
          setError('');
          setSuccess(true);
          setTimeout(()=>{
            history.push(Routes.Signin.path);
          }, 2000);
        }
      })
      .catch(()=>setError('Unknown error occurred. Please try again'))
      .finally(()=>setLoading(false));
  };
  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don&apos;t fret! Just type in your email and we will send you a code to reset your password!</p>
                {error && <div className="alert alert-danger" role="alert">
                  {error}

                </div>}
                {success && <div className="alert alert-success" role="alert">
                    Password reset link has been sent to your email
                </div>}
                <FormHandler onSubmit={submit}
                  validate={validate}
                  render={({handleSubmit})=>(
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <Form.Label htmlFor="email">Your Email</Form.Label>
                        <InputGroup id="email" className="has-validation">
                          <Field name='email' render={({input, meta}) => (<>
                            <Form.Control required autoFocus type="email" placeholder="my@email.com" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>

                        </InputGroup>
                      </div>
                      <Button variant="primary" type="submit" disabled={loading || success} className="w-100 d-inline-flex justify-content-center align-items-center">
                    Recover password {loading  && <div className={'ms-2 d-inline-flex align-items-center'}><RingLoader loading={loading} color={'white'} size={17} /></div> }
                      </Button>
                    </Form>
                  )}/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
