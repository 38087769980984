import React, {
  useCallback,
  useEffect, useLayoutEffect, useRef, useState
} from 'react';
import {
  AddNewGroupCard, GroupCard
} from './widgets';
import {AllResourcesList} from '../../services/ResourceService';
import {css} from '@emotion/react';
import {RingLoader} from 'react-spinners';
import _ from 'lodash';
import {
  Button, Col, Form, FormControl, Image, InputGroup, Modal, Row
} from '@themesberg/react-bootstrap';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import Chips from 'react-chips';
import * as Yup from 'yup';
import {
  FORM_ERROR, setIn
} from 'final-form';
import chipTheme from './chipTheme';
import {getCroppedImg} from '../../utils';
import {captureException} from '@sentry/react';
import {
  CreateGroupService, GroupsList
} from '../../services/GroupServices';
import {Link} from 'react-router-dom';

function GroupImageField({files, onChange, image, requestClose, aspectRatio, submit}){
  const [crop, setCrop] = useState({
    x: 0,
    y: 0
  }),
        [zoom, setZoom] = useState(1),
        [croppedAreaPixels, setCroppedAreaPixels] = useState(null),
        [croppedImage, setCroppedImage] = useState(null),

        onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
          setCroppedAreaPixels(croppedAreaPixels);
        }, []);

  useEffect(()=> {
    (async () => {
      try {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels
        );
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [croppedAreaPixels, image]);


  const onSubmit = ()=>{

    submit(croppedImage)
      .then(requestClose)
      .catch((err)=>{
        captureException(err);
      });
  },
        [src, setSrc] = useState(),
        [isMouseInside, setMouseInside] = useState(false),
        containerRef = useRef(),
        [containerHeight, setContainerHeight] = useState(0);

  useEffect(()=>{

    let observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.contentBoxSize){
          setContainerHeight(entry.contentRect.width);
        }
      }
    });
    observer.observe(containerRef.current);
    setContainerHeight(containerRef.current.offsetWidth);
    return ()=>observer.disconnect();
  }, [src]);


  return <div ref={containerRef} className="position-relative rounded-circle overflow-hidden" onMouseEnter={()=>setMouseInside(true)} onMouseLeave={()=>setMouseInside(false)}>
    <Image style={{
      height: `${containerHeight}px`,
      objectFit: 'contain'
    }} fluid roundedCircle src={src||'https://www.woosync.io/wp-content/uploads/2019/07/group.svg'} />
    <input className="position-absolute" style={{
      left: 0,
      bottom: 0,
      height: '45px',
      width: '100%',
      opacity: 0,
      zIndex: 10,
      cursor: 'pointer'
    }} type="file" accept="image/*" onChange={(e)=> {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setSrc(reader.result )
      );
      reader.readAsDataURL(e.target.files[0]);
      onChange(e);
    }}/>
    {isMouseInside && <div className="d-flex bg-light position-absolute justify-content-center align-items-center w-100" style={{
      left: 0,
      bottom: 0,
      height: '45px',
      zIndex: 1,
      cursor: 'pointer'
    }}>
      Change
    </div>}
  </div>;
}

function AddGroupModal({requestClose}){
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false)
  ;


  const onClose = () => {
    requestClose();
  },
        schema = Yup.object({
          name: Yup.string().required('Group name is Required'),
          description: Yup.string(),
          emails: Yup.array().ensure().min(1, 'At least one member is required to start a group').of(Yup.lazy(value1 => Yup.string().email(`${value1} is not a valid email`))),
          image: Yup.array().ensure().min(1).max(1)
        }),
        validate = async (values) => {
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
          }
        },

        submit = async (body)=>{
          let data;
          try {
            data = await CreateGroupService(body);
          } catch (err){
            return {[FORM_ERROR]: _.get(err, 'request.message', 'Unknown error occurred. Please try again later.')};
          }
          if (data.success){
            onClose();
          } else{
            return {[FORM_ERROR]: data.message};
          }

        //  TODO: Add error handling here
        };


  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={onClose} size="lg">
      <FormHandler onSubmit={submit}
        validate={validate}
        initialValues={{emails: []}}
        render={({handleSubmit, submitting, hasValidationErrors, pristine, submitError})=>(
          <Form onSubmit={handleSubmit} id="add-group-form">
            <Modal.Header>
              <Modal.Title className="h4">Create Group</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
              {submitError && <p className="alert alert-danger p-2 text-center">{submitError}</p>}
              <Row>
                <Col xs={4}>
                  <div className="position-relative rounded-circle overflow-hidden">
                    <Field name="image" render={({input})=><GroupImageField files={input.value} onChange={({target})=>input.onChange(target.files)}/>}/>
                  </div>
                </Col>
                <Col xs={8}>
                  <Form.Group id="name">
                    <Form.Label>Group Name</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='name' render={({input, meta})=>(<>
                        <Form.Control required isInvalid={meta.touched && meta.error} type="text" placeholder="Group Name" {...input}/>
                        {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                      </>)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="description">
                    <Form.Label>Description</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='description' render={({input, meta})=>(<>
                        <Form.Control required as="textarea" isInvalid={meta.touched && meta.error} type="textarea" placeholder="Group Description" {...input}/>
                        {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                      </>)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="members">
                    <Form.Label>Members</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='emails' render={({input, meta})=>
                        <>
                          <Chips theme={chipTheme} createChipKeys={[32, 13, 188]}  {...input}
                            fromSuggestionsOnly={false} uniqueChips/>
                          {meta.error && meta.error.length !== 0 && <FormControl.Feedback className="d-block"
                            type="invalid">{_.isArray(meta.error)?meta.error.filter(item=>item)[0]:meta.error}</FormControl.Feedback>}
                        </>}/>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" className="text-gray" onClick={onClose}>
                Close
              </Button>
              <Button form="add-group-form" for type="submit" variant="primary" className="d-inline-flex justify-content-center ms-auto align-items-center" disabled={hasValidationErrors || submitting||pristine} >
            Add {submitting  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={submitting} color="white" size={17} /></div>}
              </Button>

            </Modal.Footer>
          </Form>)}/>

    </Modal>
  );

}

export default function Groups() {
  let [groupList, setGroupList] = useState([]),
      [shouldShowAddModal, showAddModal ] = useState(false),
      [loading, setLoading] = useState(false);

  const loaderCSS = css`
  display: inline-block;
`,

        fetchData = ()=>{
          setLoading(true);
          GroupsList()
            .then((data)=>{
              setGroupList(data.groups);
            }).finally(()=>{
              setLoading(false);
            });
        };

  useEffect(()=>{
    fetchData();
  }, []);

  return (
    <>
      {shouldShowAddModal &&<AddGroupModal requestClose={() => {
        showAddModal(false);
        fetchData();
      }}/>}
      <div className='d-flex flex-wrap'>
        {
          !loading &&
            <>
              {groupList.map((group) => (
                <Link to={`/groups/${group.id}`} className="me-3 mb-3" key={group.id}>
                  <GroupCard {...group}/>
                </Link>
              ))}
              <AddNewGroupCard onClick={()=>showAddModal(true)} />
            </>

        }
        {loading && <div className="w-100 text-center">
          <RingLoader css={loaderCSS} className="d-inline-block" loading={loading}/>
        </div>}
      </div>
    </>
  );

}
