import _ from 'lodash';
export function setCookie(cname, cvalue, exdays) {
  if(!cvalue){
    return;
  }
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = 'expires='+ d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function isProfileComplete(sponsorProfile){
  const fields = ['name', 'qualification', 'handphone_number', 'profile_link', 'picture', 'title', 'company', 'reviewOne', 'reviewTwo', 'downloadLink', 'galleryOne', 'galleryTwo', 'telegram'],
        values = fields.map((val)=>_.get(sponsorProfile, val, '')).map(item=> !!item);
  return _.every(values);
}

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
}
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc),
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),

        maxSize = Math.max(image.width, image.height),
        safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
    }, 'image/png');
  });
}

export function calculateAmount(qty, base_price){
  const billing_scheme = base_price.billing_scheme;
  let cost = 0, tiers, tier;
  if(billing_scheme === 'Tiered') {
    tiers = base_price.tiers;
    tiers = tiers.filter(x => (x.upto || -1) >= qty);
    if( tiers.length === 0){
      tier = base_price.tiers.filter(x => ! ('upto' in x))[0];
    } else {
      tier = _.minBy(tiers, 'upto');
    }

    if(tier.flat_amount){
      cost += tier.flat_amount;
    }
    if(tier.unit_amount){
      cost += qty * tier.unit_amount;
    }

  } else {
    cost += base_price.unit_amount * qty;
  }

  return cost;
}
