import UserStore from './UserStore';
import CartStore from './CartStore';
import ChatStore from './ChatStore';

const stores = {
  UserStore,
  CartStore,
  // ChatStore
};
export default function getStore(storeName){
  return stores[storeName];
}
