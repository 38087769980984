import React, {useState} from 'react';
import {
  Button, Form, Modal
} from '@themesberg/react-bootstrap';
import _ from 'lodash';
import {
  DeleteFwsTestLinks, FWSTestLinkAddCredit
} from '../../services/ReportService';
import {RingLoader} from 'react-spinners';

export function AddFwsCreditModal({link, requestClose}) {
  let [error, setError] = useState(false),
      [credits, setCredits] = useState(false),
      [loading, setLoading] = useState(false);


  const onClose = () => {
    setCredits();
    requestClose();
  },

        submit = () =>{
          setError('');
          if(credits <=0 ){
            setError('Invalid Credits amount. Must be greater than 0.');
            return;
          }
          setLoading(true);
          return FWSTestLinkAddCredit({
            link_uuid: link.uuid,
            credits
          })
            .then((resp)=>{
              if(!resp.success){
                setError(resp.message);
              } else {
                onClose();
              }
            })
            .catch((err)=>{
              setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
            }).finally(()=>{
              setLoading(false);
            });
        };

  return (
    <Modal as={Modal.Dialog} centered show={!_.isEmpty(link)} onHide={onClose}>
      <Modal.Header>
        <Modal.Title className="h6">Add Credits</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={onClose}/>
      </Modal.Header>
      <Modal.Body>
        <p>Current Credits: {_.get(link, 'credits')}</p>
        {error && <p className="text-danger">{error}</p>}
        <Form.Control type="number" value={credits} onChange={(e) => setCredits(e.target.value)}/>
      </Modal.Body>
      <Modal.Footer>
        {/*TODO: test this*/}
        <Button variant="primary" className="d-inline-flex justify-content-center align-items-center" disabled={!credits || loading} onClick={submit}>
          Add {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteFwsLinkModal({link, requestClose}) {
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);

  return (
    <Modal as={Modal.Dialog} centered show={!_.isEmpty(link)} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Delete Link?</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        <p>Are you sure you want to delete the link: {link.test_link}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="d-inline-flex justify-content-center align-items-center" disabled={loading} onClick={() => {
          setError('');
          setLoading(true);
          DeleteFwsTestLinks({testId: link.uuid})
            .then((resp) => {
              if(!resp.success){
                setError(resp.message);
              } else {
                requestClose();
              }
            }) .catch((err)=>{
              setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
            })
            .finally(()=>{
              setLoading(false);
            });
        }}>
          Yes {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
