import HTTPService from './HTTPService';

export async function SubSponsorsList(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/child_sponsors`
  });
}

export async function CampaignDetailsList(){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/get-campaign-data`
  });
}

//
// export async function FWSTestsCount(){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/fws/count`);
// }
//
// export async function PsychometricsTests({from, size}){
//   const searchParams = new URLSearchParams({
//     from,
//     size
//   });
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/psychometrics?${searchParams.toString()}`);
// }
//
// export async function PsychometricsTestsCount(){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/reports/psychometrics/count`);
// }
//
// export async function LatestTests({from=0, size=10}){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/reports/latestTests`);
// }
//
export async function TransferCredits({email, credits, report_type}){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/transfer-credits`,
    method: 'post',
    json: {
      email,
      credits,
      report_type
    }
  });
}
export async function DeleteSponsor({email}){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/delete`,
    method: 'post',
    json: {email}
  });
}
//
// export async function FWSTestLinks({from, size}){
//   const searchParams = new URLSearchParams({
//     from,
//     size
//   });
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/fws/generated-tests?${searchParams.toString()}`);
// }
//
// export async function FWSTestLinksCount(){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/fws/generated-tests/count`);
// }
//
// export async function FWSTestLinkAddCredit({link_uuid, credits}){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/fws/add-credit`, 'POST', {link_uuid, credits});
// }
//
// export async function ProfileTestLinks({from, size}){
//   const searchParams = new URLSearchParams({
//     from,
//     size
//   });
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/generated-tests?${searchParams.toString()}`);
// }
//
// export async function ProfileTestLinksCount(){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/generated-tests/count`);
// }
//
// export async function ProfileTestLinkAddCredit({link_uuid, credits}){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/add-credit`, 'POST', {link_uuid, credits});
// }
//
// export async function DeleteProfileTestLinks({testId}){
//   return await HTTPService(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/sponsor/links/delete`, 'post', {link_uuid: testId});
// }
