import React, {useState} from 'react';
import {
  Button, Col, Form, FormControl, InputGroup, Modal, Row
} from '@themesberg/react-bootstrap';

import {
  CreateGroupResourcesService,
  CreateGroupService,
  DeleteGroup,
  DeleteInvitationService, DeleteResourceService,
  InviteMembersService
} from '../../services/GroupServices';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../routes';
import * as Yup from 'yup';
import {setIn} from 'final-form';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import Chips from 'react-chips';
import chipTheme from './chipTheme';
import _ from 'lodash';
import {RingLoader} from 'react-spinners';
import {GenerateTestLinkWidget} from '../../components/Widgets';
import {useParams} from 'react-router-dom';

export function DeleteGroupModal({groupInfo: {groupId, groupName}, requestClose}) {
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);
  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Delete Link?</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="alert alert-danger p-2 text-center">{error}</p>}
        <p>Are you sure you want to delete the group: {groupName}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="d-inline-flex justify-content-center align-items-center" disabled={loading} onClick={() => {
          setError('');
          setLoading(true);
          DeleteGroup({groupId})
            .then((resp) => {
              if(!resp.success){
                setError(resp.message);
              } else {
                requestClose();
              }
            }) .catch((err)=>{
              setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
            })
            .finally(()=>{
              setLoading(false);
            });
        }}>
                    Yes {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
                    No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function CreateGroupTestLinkModal({requestClose}){
  const {groupId} = useParams();
  return <Modal as={Modal.Dialog} centered show={true} onHide={requestClose}>
    <Modal.Header>
      <Modal.Title className="h6">Create Link?</Modal.Title>
      <Button variant="close" aria-label="Close" onClick={requestClose}/>
    </Modal.Header>
    <GenerateTestLinkWidget groupId={groupId} onSave={requestClose}/>
  </Modal>;
}

export function InviteMembersModal({groupId, requestClose}){
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);


  const onClose = () => {
    requestClose();
  },
        schema = Yup.object({emails: Yup.array().ensure().min(1, 'At least one member is required to start a group').of(Yup.lazy(value1 => Yup.string().email(`${value1} is not a valid email`))), }),
        validate = async (values) => {
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
          }
        },

        submit = (body)=>{
          return InviteMembersService({
            emails: body,
            groupId: groupId
          })
            .then(()=>{
              onClose();
            });
          //  TODO: Add error handling here
        };


  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={onClose} size="lg">
      <FormHandler onSubmit={submit}
        validate={validate}
        initialValues={{emails: []}}
        render={({handleSubmit, submitting, hasValidationErrors, pristine})=>(
          <Form onSubmit={handleSubmit} id="invite-members-form">
            <Modal.Header>
              <Modal.Title className="h4">Invite Members</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={8}>
                  <Form.Group id="members">
                    <Form.Label>Members</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='emails' render={({input, meta})=>
                        <>
                          <Chips theme={chipTheme} createChipKeys={[32, 13, 188]}  {...input}
                            fromSuggestionsOnly={false} uniqueChips/>
                          {meta.error && meta.error.length !== 0 && <FormControl.Feedback className="d-block"
                            type="invalid">{_.isArray(meta.error)?meta.error.filter(item=>item)[0]:meta.error}</FormControl.Feedback>}
                        </>}/>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" className="text-gray" onClick={onClose}>
                                         Close
              </Button>
              <Button form="invite-members-form" for type="submit" variant="primary" className="d-inline-flex justify-content-center ms-auto align-items-center" disabled={hasValidationErrors || submitting||pristine} >
                                         Add {submitting  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={submitting} color="white" size={17} /></div>}
              </Button>

            </Modal.Footer>
          </Form>)}/>

    </Modal>
  );

}

export function DeleteInvitationModal({invitationId, requestClose}) {
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);
  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Delete Invitation?</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        <p>Are you sure you want to delete this invitation ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="d-inline-flex justify-content-center align-items-center" disabled={loading} onClick={() => {
          setError('');
          setLoading(true);
          console.log('rahul-before service');
          DeleteInvitationService({invitationId: invitationId})
            .then((resp) => {
              if(!resp.success){
                setError(resp.message);
              } else {
                requestClose();
              }
            }) .catch((err)=>{
              setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
            })
            .finally(()=>{
              setLoading(false);
            });
        }}>
                    Yes {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
                    No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export function DeleteResourceModal({resourceId, requestClose}) {
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false),
      {groupId} = useParams();
  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Delete Resource?</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        <p>Are you sure you want to delete this resource ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="d-inline-flex justify-content-center align-items-center" disabled={loading} onClick={() => {
          setError('');
          setLoading(true);
          DeleteResourceService({
            resourceId,
            groupId
          })
            .then((resp) => {
              if(!resp.success){
                setError(resp.message);
              } else {
                requestClose();
              }
            }) .catch((err)=>{
              setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
            })
            .finally(()=>{
              setLoading(false);
            });
        }}>
                    Yes {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
                    No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export function DeleteResource({groupId, resourceId, resourceName, requestClose}) {
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);
  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Delete Resource?</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        <p>Are you sure you want to delete resourceName ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="d-inline-flex justify-content-center align-items-center" disabled={loading} onClick={() => {
          setError('');
          setLoading(true);
          // DeleteInvitationService({invitationId: invitationId})
          //   .then((resp) => {
          //     if(!resp.success){
          //       setError(resp.message);
          //     } else {
          //       requestClose();
          //     }
          //   }) .catch((err)=>{
          //     setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
          //   })
          //   .finally(()=>{
          //     setLoading(false);
          //   });
        }}>
                    Yes {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
                    No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export function UploadResourceModal({groupId, requestClose}){
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);

  const FILE_SIZE = 505*1024*1024, SUPPORTED_FORMATS = ['application/pdf', 'video/mp4'],
        onClose = () => {
          requestClose();
        },
        schema = Yup.object({
          title: Yup.string().required(),
          file: Yup.mixed() .test('fileSize', 'File Size is too large', value => value.size <= FILE_SIZE) .test('type', 'Unsupported File Format', value => SUPPORTED_FORMATS.includes(value.type))
        }),
        validate = async (values) => {
          console.log(JSON.stringify(values));
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            // eslint-disable-next-line no-debugger
            debugger;
            console.log(JSON.stringify(err.inner));
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
          }
        },

        submit = (body)=>{
          return CreateGroupResourcesService({
            group_id: groupId,
            ...body
          })
            .then(()=>{
              onClose();
            });
          //  TODO: Add error handling here
        };


  return (
    <Modal as={Modal.Dialog} centered show={true} onHide={onClose} size="lg">
      <FormHandler onSubmit={submit}
        // validate={validate}
        render={({handleSubmit, submitting, hasValidationErrors, pristine})=>(
          <Form onSubmit={handleSubmit} id="invite-members-form">
            <Modal.Header>
              <Modal.Title className="h4">Add Resource</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <Form.Group id="members">
                    <Form.Label>Title</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='title' render={({input, meta})=>
                        <>
                          <Form.Control type="" isValid={meta.touched && meta.error} {...input} />
                          {meta.touched && meta.error && <FormControl.Feedback className="d-block"
                            type="invalid">{meta.error}</FormControl.Feedback>}
                        </>}/>
                    </InputGroup>

                    <Form.Label>File</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='file' render={({input: {value, ...input}, meta})=>
                        <>
                          <Form.Control type="file" isValid={meta.touched && meta.error} {...input} fileName={value.name}  onChange={(e)=>input.onChange(e.target.files[0])} accept={SUPPORTED_FORMATS.join(',')}/>
                          {meta.touched && meta.error && <FormControl.Feedback className="d-block" type="invalid">{meta.error}</FormControl.Feedback>}
                        </>}/>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" className="text-gray" onClick={onClose}>
                           Close
              </Button>
              <Button form="invite-members-form" for type="submit" variant="primary" className="d-inline-flex justify-content-center ms-auto align-items-center" disabled={hasValidationErrors || submitting||pristine} >
                           Add {submitting  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={submitting} color="white" size={17} /></div>}
              </Button>

            </Modal.Footer>
          </Form>)}/>

    </Modal>
  );

}
