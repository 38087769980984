import React, {useState} from 'react';
import {Button, Col, Form, Modal} from '@themesberg/react-bootstrap';
import _ from 'lodash';
import {DeleteSponsor, TransferCredits} from '../../services/SponsorManagementService';
import {RingLoader} from 'react-spinners';

export function TransferCreditsModal({subSponsor, requestClose}) {
  let [error, setError] = useState(false),
      [credits, setCredits] = useState(false),
      [reportType, setReportType] = useState('profile'),
      [loading, setLoading] = useState(false);


  const submit = () =>{
    setError('');
    if(credits <=0 ){
      setError('Invalid Credits amount. Must be greater than 0.');
      return;
    }
    setLoading(true);
    return TransferCredits({email: subSponsor.email,
      credits,
      report_type: reportType})
      .then((resp)=>{
        if(!resp.success){
          setError(resp.message);
        }
        else {
          requestClose();
          setCredits();
          setReportType('profile');
        }

      })
      .catch((err)=>{
        setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
      })
      .finally(()=>{
        setLoading(false);
      });
  };

  return (
    <Modal as={Modal.Dialog} centered show={!_.isEmpty(subSponsor)} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Transfer Credits to {_.get(subSponsor, 'name')}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        <Form.Group className="mb-2">
          <Form.Label>Report Type</Form.Label>
          <Form.Control onChange={(e) => setReportType(e.target.value)} value={reportType} as="select" placeholder="Select Report Type"  >
            <option value='profile'>Profile</option>
            <option value='fws'>FWS</option>
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Credits</Form.Label>
          <Form.Control type="number" value={credits} onChange={(e) => setCredits(e.target.value)}/>
        </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={!credits && loading} onClick={submit} className="d-inline-flex justify-content-center align-items-center">
          Add Credits {loading  && <div className={'ms-2 d-inline-flex align-items-center'}><RingLoader loading={loading} color={'white'} size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteSponsorModal({subSponsor, requestClose}) {
  let [error, setError] = useState(false),
      [loading, setLoading] = useState(false);

  const submit = () =>{
    setError('');
    setLoading(true);
    return DeleteSponsor({email: subSponsor.email})
      .then((resp)=>{
        if(!resp.success){
          setError(resp.message);
        }
        else {
          requestClose();
        }

      })
      .catch((err)=>{
        setError(_.get(err, 'request.message', 'Unknown error occured. Please try again later.'));
      })
      .finally(()=>{
        setLoading(false);
      });
  };

  return (
    <Modal as={Modal.Dialog} centered show={!_.isEmpty(subSponsor)} onHide={requestClose}>
      <Modal.Header>
        <Modal.Title className="h6">Delete {_.get(subSponsor, 'name')}&apos;s Account</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={requestClose}/>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        <p>Are you sure you want to delete the sponsor account for {_.get(subSponsor, 'name')}? The credits in the sponsors account will be transferred to your account.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={submit} className="d-inline-flex justify-content-center align-items-center">
          Delete {loading  && <div className={'ms-2 d-inline-flex align-items-center'}><RingLoader loading={loading} color={'white'} size={17} /></div>}
        </Button>
        <Button variant="light" className="text-gray ms-auto" onClick={requestClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
