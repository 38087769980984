import {
  MessageDirection,
  MessageContentType, User, Presence, UserStatus, Conversation, Participant, ConversationRole, TypingUsersList,
} from '@chatscope/use-chat';
import {getSocket} from './socketFactory';
import moment from 'moment';
import {AvatarGenerator} from 'random-avatar-generator';
// import ReconnectingWebSocket from 'reconnecting-websocket';
const generator = new AvatarGenerator();

export class ChatService {

  constructor(storage, user_id) {
    this.storage = storage;
    this.rws = getSocket(`${process.env.REACT_APP_WSS_ENDPOINT}/ws/chat/sponsor/${user_id}`);
    this.eventHandlers = {
      onMessage: () => {},
      onConnectionStateChanged: () => {},
      onUserConnected: () => {},
      onUserDisconnected: () => {},
      onUserPresenceChanged: () => {},
      onUserTyping: () => {},
    };
    // For communication we use CustomEvent dispatched to the window object.
    // It allows you to simulate sending and receiving data from the server.
    // In a real application, instead of adding a listener to the window,
    // you will implement here receiving data from your chat server.

    this.rws.addEventListener('open', (evt)=>{
    });

    this.rws.addEventListener('message', (evt) => {
      const event = JSON.parse(evt.data);
      if(event.type === 'message'){
        const message = event.message;
        storage.addMessage({
          id: message.id,
          content: message.content,
          contentType: MessageContentType.TextPlain,
          direction: message.sender === 'TT'?MessageDirection.Incoming:MessageDirection.Outgoing,
          senderId: message.senderId,
          createdAt: moment(message.created_at).utc()
        }, message.conversation);
        if (message.conversation === storage.activeConversationId){
          this.markRead(message.conversation);
        }
      }
      if(event.type === 'start_conversation'){
        const conversation = event.conversation;
        storage.addUser(new User({
          id: conversation.test_taker.id,
          presence: new Presence({
            status: UserStatus.Available,
            description: ''
          }),
          firstName: '',
          lastName: '',
          email: conversation.test_taker.email,
          bio: '',
          username: conversation.test_taker.name,
          avatar: generator.generateRandomAvatar(conversation.test_taker.id)
        }));
        storage.addConversation(new Conversation({
          id: conversation.id,
          participants: [new Participant({
            id: conversation.test_taker.id,
            role: new ConversationRole([])
          })],
          unreadCounter: conversation.unread_count,
          typingUsers: new TypingUsersList({items: []}),
          draft: ''
        }));
      }


    });
  }

  markRead(conversationId){
    const messageEvent = {
      type: 'mark_read',
      conversationId
    };
    this.rws.send(JSON.stringify(messageEvent));
  }

  startConversation({user_id}){
    this.rws.send(JSON.stringify({
      type: 'start_conversation',
      user_id
    }));
  }

  sendMessage({ message, conversationId }) {
    // We send messages using a CustomEvent dispatched to the window object.
    // They are received in the callback assigned in the constructor.
    // In a real application, instead of dispatching the event here,
    // you will implement sending messages to your chat server.
    const messageEvent = {
      type: 'message',
      message,
      conversationId
    };
    this.rws.send(JSON.stringify(messageEvent));
  }

  sendTyping({isTyping,
    content,
    conversationId,
    userId, }) {
    // We send the "typing" signalization using a CustomEvent dispatched to the window object.
    // It is received in the callback assigned in the constructor
    // In a real application, instead of dispatching the event here,
    // you will implement sending signalization to your chat server.
    const typingEvent = new CustomEvent('chat-protocol', {detail: {
      type: 'typing',
      isTyping,
      content,
      conversationId,
      userId,
      sender: this,
    }, });

    window.dispatchEvent(typingEvent);
  }

  // The ChatProvider registers callbacks with the service.
  // These callbacks are necessary to notify the provider of the changes.
  // For example, when your service receives a message, you need to run an onMessage callback,
  // because the provider must know that the new message arrived.
  // Here you need to implement callback registration in your service.
  // You can do it in any way you like. It's important that you will have access to it elsewhere in the service.
  on(
    evtType,
    evtHandler
  ) {
    const key = `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`;

    if (key in this.eventHandlers) {
      this.eventHandlers[key] = evtHandler;
    }
  }

  // The ChatProvider can unregister the callback.
  // In this case remove it from your service to keep it clean.
  off(
    evtType,
    eventHandler
  ) {
    const key = `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`;
    if (key in this.eventHandlers) {
      this.eventHandlers[key] = () => {};
    }
  }
}
