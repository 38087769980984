import {
  action, computed, makeObservable, observable
} from 'mobx';
import {ChatService} from '../chat/ChatService';
import getStore from './index';
import _ from 'lodash';
import {
  ConversationsHistory, ConversationsList
} from '../services/ChatSupportServices';
import { AvatarGenerator } from 'random-avatar-generator';

import {
  Conversation,
  ConversationRole,
  MessageContentType,
  MessageDirection,
  Participant,
  Presence,
  TypingUsersList,
  User,
  UserStatus
} from '@chatscope/use-chat';
import moment from 'moment';


class ChatStore {
    activeConversationId = 0;
    conversations = []
    messages = {};
    service = null;
    users = []
    hyderated = false
    loadingState = {};
    loadingConversations = false;
    drafts = {}
    constructor() {
      makeObservable(this, {
        drafts: observable,
        activeConversationId: observable,
        conversations: observable,
        messages: observable,
        service: observable,
        users: observable,
        fetchHistory: action,
        hyderated: observable,
        activeConversation: computed,
        initialise: action,
        addConversation: action,
        currentMessages: computed,
        addMessage: action,
        loadingState: observable,
        loadingConversations: observable,
        fetchConversations: action,
        setActiveConversationId: action,
        currentUserAvatar: computed,
        currentUserName: computed,
        addUser: action,
        saveDraft: action,
        draft: computed,
        sendMessage: action,
        loadingActiveConversation: computed
      });
      this.setActiveConversationId = this.setActiveConversationId.bind(this);
      this.saveDraft = this.saveDraft.bind(this);
      this.sendMessage = this.sendMessage.bind(this);
    }

    get loadingActiveConversation(){
      return _.get(this.loadingState, this.activeConversationId, false);
    }

    get activeConversation(){
      return _.find(this.conversations, {'id': this.activeConversationId});
    }

    initialise() {

      this.service = new ChatService(this,  getStore('UserStore').data.user_id);
      this.hyderated = true;
      this.fetchConversations();
    }

    fetchConversations(){

      const generator = new AvatarGenerator();
      this.loadingConversations = true;
      ConversationsList()
        .then(r => {
          for (let conversation of r.data){
            this.addUser(new User({
              id: conversation.test_taker.id,
              presence: new Presence({
                status: UserStatus.Available,
                description: ''
              }),
              firstName: '',
              lastName: '',
              email: conversation.test_taker.email,
              bio: '',
              username: conversation.test_taker.name,
              avatar: generator.generateRandomAvatar(conversation.test_taker.id)
            }));


            this.addConversation(new Conversation({
              id: conversation.id,
              participants: [new Participant({
                id: conversation.test_taker.id,
                role: new ConversationRole([])
              })],
              unreadCounter: conversation.unread_count,
              typingUsers: new TypingUsersList({items: []}),
              draft: ''
            }));
          }
        })
        .finally(action(()=>{
          this.loadingConversations = false;

        }));
    }

    fetchHistory(){
      let currentConvId = this.activeConversationId;
      this.loadingState[currentConvId] = true;
      ConversationsHistory({conversationId: currentConvId})
        .then(r => {
          for (let message of r.data){
            let activeConversation = this.activeConversation,
                user_id =  _.get(activeConversation, 'participants.0.id');
            this.addMessage({
              id: message.id,
              content: message.content,
              contentType: MessageContentType.TextPlain,
              direction: message.sender === 'SP'?MessageDirection.Outgoing:MessageDirection.Incoming,
              senderId: message.sender === 'SP'? getStore('UserStore').data.user_id : user_id,
              createdAt: moment(message.created_at).utc()
            }, currentConvId);
          }
        })
        .finally(action(()=>{
          this.loadingState[currentConvId] = false;

        }));
    }

    addConversation(conversation){
      this.conversations.push(conversation);
    }

    get currentMessages(){
      return _.chain(this.messages[this.activeConversationId]).sortBy(['createdAt']).sortedUniqBy((item)=>item.id).reduce((result, message) => {
        if (result.length === 0 || _.last(result).direction !== message.direction) {
          return result.concat([{
            direction: message.direction,
            messages: [message],
            id: message.id
          }]);
        } else {
          let last = result.length - 1;
          result[last].messages.push(message);
          return result;
        }
      }, []).value();
      // sort messages
      // group consecutive messsages by direction in to groups
      // return the list of groups
    }

    addMessage(message, conversationId){
      if (this.messages[conversationId]){
        let index = _.sortedIndexBy(this.messages[conversationId], message, 'createdAt');
        this.messages[conversationId].splice(index, 0, message);
      } else{

        this.messages[conversationId] = [message];
      }
    }

    addUser(user){
      this.users.push(user);
    }

    get currentUserName(){
      let activeConversation = this.activeConversation,
          user_id =  _.get(activeConversation, 'participants.0.id'),
          user =  _.find(this.users, {id: user_id});
      return _.get(user, 'username');
    }
    get currentUserAvatar(){
      let activeConversation = this.activeConversation,
          user_id =  _.get(activeConversation, 'participants.0.id'),
          user =  _.find(this.users, {id: user_id});
      return _.get(user, 'avatar');
    }
    setActiveConversationId(conv_id){
      this.activeConversationId = conv_id;
      let index = _.findIndex(this.conversations, {id: conv_id});
      this.service.markRead(conv_id);
      this.conversations[index].unreadCounter = 0;
      this.fetchHistory();
    }

    saveDraft(message){
      this.drafts[this.activeConversationId] = message;
    }
    get draft(){
      return this.drafts[this.activeConversationId];
    }

    sendMessage(){
      const message = this.draft;
      this.service.sendMessage({
        message: message,
        conversationId: this.activeConversationId
      });
      this.addMessage({
        id: new Date().getTime(),
        content: message,
        contentType: MessageContentType.TextPlain,
        direction: MessageDirection.Outgoing,
        senderId: getStore('UserStore').data.user_id,
        createdAt: moment().utc()
      }, this.activeConversationId);
      this.saveDraft('');
    }
}

export default new ChatStore();
